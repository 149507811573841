import { useCallback, useEffect, useState } from "react";
import AddProductForm from "./modals/AddProductForm";
import { IProduct } from "../interfaces/IHub";
import { ITableColumn, XTable } from "../../../components/shared/XTable";
import {
  IHeaderButtonProps,
  SettingsPageHeader,
} from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import HubProductDetailsPage from "./HubProductDetailsPage";
import { IHubInput } from "../inputs/interfaces/IInput";
import XPagination from "../../../components/shared/XPagination";
import {IPaginationMetadata} from "../../../interfaces/IApiResponse";
import {useAuth} from "../../auth";
import {FormattedMessage, useIntl} from "react-intl";
import debounce from "lodash.debounce";

interface IProductPageProps {
  products: IProduct[];
}

const columns: ITableColumn[] = [
    {
        localeId: "TABLE.COLUMN.PRODCTNAME",
        label: 'Product Name',
        id: 'name',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        localeId: "TABLE.COLUMN.PRODUCTCATEGORY",
        label: 'Product Category',
        id: 'category',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        localeId: "TABLE.COLUMN.DESCRIPTION",
        label: 'Description',
        id: 'description',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
]


const HubProductsPage = ({ products }: IProductPageProps) => {
    const intl = useIntl();

    const [importing, setImporting] = useState<boolean>(false);
  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 8,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: products.length,
    totalPages: 0,
  });
  const initialValues = {
    id: "",
    name: "",
    category: "",
    description: "",
    varieties: [],
  };

  const [selectedProduct, setSelectedProduct] =
    useState<IProduct>(initialValues);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const auth = useAuth();
  const productCategories = auth.categories?.productCategories;

  const filterProducts = () => {
    const data: IProduct[] = products.map((p: IProduct) => ({
      ...p,
      id: p.id,
      name: p.name,
      description: p.description,
      category: productCategories?.find(
        (c) => c.number.toString() == p.category,
      )?.name,
    }));

    if (searchTerm) {
      setFilteredProducts(
        data.filter((i) =>
          i.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredProducts(data);
    }
  };

  useEffect(() => {
    filterProducts();
  }, [searchTerm]);

    const actionButtons: IHeaderButtonProps[] = [{
        label: `${intl.formatMessage({id: "BUTTONS.ADDPRODUCT", defaultMessage: "Add a Product"})}`,
        cssClass: "btn btn-primary btn-sm",
        dataTarget: "#add-product",
        dataToggle: "modal",
        onSubmit: (values: any) => {
        },
        processing: importing
    }];

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: IHubInput) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: IProduct) => {
    setDrawerVisible(!drawerVisible);
    setSelectedProduct(item);
  };

  return (
    <>
      <SettingsPageHeader title={"Settings > Hub Products"} />
      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-auto">
            <input
              type="text"
              // onKeyUp={(event) => handleSearch(event)}
              onChange={handleSearchChange}
              className="form-control"
              placeholder="Type to search..."
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

                    <div className="action-buttons">

                        {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
                        {/*    <FontAwesomeIcon icon={faFilter} className="text-orange"/>*/}
                        {/*    <span className="ms-2">Filters</span>*/}
                        {/*</button>*/}
                        {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">*/}
                        {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
                        {/*</button>*/}
                        {showActionButtons &&
                            actionButtons.map((button, index) => {
                                return (<button
                                    data-bs-toggle={button.dataToggle}
                                    data-bs-target={button.dataTarget}
                                    className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={button.onClick}>
                                    {button.processing ? 'Please wait...' : button.label}
                                </button>)
                            })
                        }
                    </div>
                </div>

        <XTable
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={filteredProducts}
          columns={columns}
          loading={false}
        />

        <XPagination
          dataLength={filteredProducts.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <HubProductDetailsPage
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        product={selectedProduct}
      />
      <AddProductForm items={products} />
    </>
  );
};

export default HubProductsPage;
