import { useDispatch } from "react-redux";
import { Field, Formik, useField } from "formik";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { ICollectionCentre, IUnit } from "../../interfaces/IHub";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { post } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { useAuth } from "../../../auth";
import { UnitSchema } from "../interfaces/UnitSchema";
import { IPaginationMetadata } from "../../../../interfaces/IApiResponse";
import {useIntl} from "react-intl";

type Props = {
    pagination: IPaginationMetadata
}

const CreateUnit = ({ pagination }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        name: '',
        displayName: ''
    }

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {


        const url = buildUrl(remoteRoutes.onboardingService, '/units')
        const data: IUnit = {
            name: values.name,
            displayName: values.displayName
        }

        post(url, data, (response) => {

            CloseModal("new-unit", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_UNITS,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
    }, []);

    const validationSchema = UnitSchema(intl)
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>

            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id="new-unit"
                    title={intl.formatMessage({id: "FORM.TITLE.CREATENEWUNIT", defaultMessage: "Add a New Unit"})}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={intl.formatMessage({id: "FORM.NAME", defaultMessage: "Name"})}
                                name="name"
                            />
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={intl.formatMessage({id: "FORM.DISPLAYNAME", defaultMessage: "Display Name"})}
                                name="displayName"
                            />
                        </div>

                    </div>

                </ModalWrapper>
            }}

        </Formik>
    );
}

export default CreateUnit;