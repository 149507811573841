import * as Yup from "yup";
import {IntlShape} from "react-intl";

export const SellBatchSchema = Yup.object({
    quantity: Yup.string().required('Required'),
    buyerId: Yup.object().nullable().required('Required'),
})

export const SellPurchasesSchema = (intl: IntlShape) => Yup.object({
    buyer: Yup.object().nullable().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
})