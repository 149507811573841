import {
  IHeaderButtonProps,
  SettingsPageHeader,
} from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import {
  IRowAction,
  ITableColumn,
  XTable,
} from "../../../components/shared/XTable";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { IInput, IProcess, IUnit } from "../interfaces/IHub";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import {
  ISettingsState,
  SETTINGS_CONSTANTS,
} from "../../../../data/redux/settings/settingsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faFilter,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import {
  IProductUnit,
  ISystemProduct,
} from "../products/interfaces/ISystemProduct";
import CreateUnit from "./modals/CreateUnitForm";
import EditUnit from "./modals/EditUnitForm";
import { DeleteUnit } from "./modals/DeleteUnit";
import debounce from "lodash.debounce";
import {FormattedMessage, useIntl} from "react-intl";

const columns: ITableColumn[] = [
    {
        localeId: "TABLE.COLUMN.NAME",
        label: 'Name',
        id: 'name',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        localeId: "TABLE.COLUMN.DISPLAYNAME",
        label: 'Display Name',
        id: 'displayName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
]

type IProps = {
  title?: string;
};

const Units = () => {

    const intl = useIntl();
    const dispatch = useDispatch();

  const initialValues = {
    name: "",
    displayName: "",
  };

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredUnits, setFilteredUnits] = useState<IUnit[]>([]);

  const { units, loading }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );
  const { pagination, data } = units;
  const [importing, setImporting] = useState<boolean>(false);
  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [selectedUnit, setSelectedUnit] = useState<IUnit>(initialValues);
  const [id, setId] = useState<string>("");

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: SETTINGS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const filterUnits = () => {
    const data: IUnit[] = units?.data;
    if (searchTerm) {
      setFilteredUnits(
        data?.filter(
          (unit) =>
            unit.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            unit.displayName
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredUnits(data);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  useEffect(() => {
    filterUnits();
  }, [units, searchTerm]);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [page, searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.onboardingService) {
      let params: any = { page, pageSize };

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(remoteRoutes.onboardingService, "/units", params);
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          dispatch({
            type: SETTINGS_CONSTANTS.FETCH_UNITS,
            payload: { data, pagination: paginationMetadata },
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: ISystemProduct) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: IUnit) => {
    setDrawerVisible(!drawerVisible);
    setSelectedUnit(item);
  };

    const actionButtons: IHeaderButtonProps[] = [{
        label: `${intl.formatMessage({id: "BUTTONS.ADDUNIT", defaultMessage: "Add a New Unit"})}`,
        cssClass: "btn btn-primary btn-sm",
        dataTarget: "#new-unit",
        dataToggle: "modal"
    }];

  const rowActions: IRowAction[] = [
    {
      label: "Edit",
      onClick: (item: IUnit) => {
        setSelectedUnit(item ?? "");
      },
      icon: "pencil",
      dataTarget: "#edit-unit",
      dataToggle: "modal",
    },
    {
      label: "Delete",
      onClick: (item: IUnit) => {
        setId(item.id ?? "");
      },
      icon: "trash",
      dataTarget: "#delete-unit",
      dataToggle: "modal",
    },
  ];

    return (
        <>
            <SettingsPageHeader title={intl.formatMessage({
                id: "HEADERS.SETTINGS.UNITS",
                defaultMessage: "Settings > Units"
            })}/>
            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-auto">
                        <input type="text" onKeyUp={handleSearch}
                               className="form-control"
                               placeholder={intl.formatMessage({
                                   id: "TABLE.SEARCH",
                                   defaultMessage: "Type to search...",
                               })}
                        />
                        <span className="input-group-text" id="addon-wrapping">
                                <FontAwesomeIcon icon={faSearch}/>
                            </span>
                    </div>

                    <div className="action-buttons">

                        {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
                        {/*    <FontAwesomeIcon icon={faFilter} className="text-orange"/>*/}
                        {/*    <span className="ms-2">*/}
                        {/*        <FormattedMessage*/}
                        {/*            id={"BUTTONS.FILTERS"}*/}
                        {/*            defaultMessage={"Filters"}*/}
                        {/*        />*/}
                        {/*    </span>*/}
                        {/*</button>*/}
                        {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">*/}
                        {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
                        {/*</button>*/}

                        {showActionButtons &&
                            actionButtons.map((button, index) => {
                                return (<button
                                    data-bs-toggle={button.dataToggle}
                                    data-bs-target={button.dataTarget}
                                    className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={button.onClick}>
                                    {button.processing
                                        ? `${intl.formatMessage({
                                            id: "LOADERS.PLEASEWAIT",
                                            defaultMessage: "Please wait...",
                                        })}`
                                        : button.label}
                                </button>)
                            })
                        }

                    </div>
                </div>

        <XTable
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          checked={false}
          rowActions={rowActions}
          data={filteredUnits}
          columns={columns}
          loading={loading}
        />

        <XPagination
          dataLength={filteredUnits.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <CreateUnit pagination={pagination} />
      <EditUnit initialValues={selectedUnit} />
      <DeleteUnit id={id} />
    </>
  );
};

export default Units;
