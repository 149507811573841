import {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Field, Formik} from "formik";
import {Autocomplete, TextField} from "@mui/material";
import {IState} from "../../../../../data/types";
import {useAuth} from "../../../auth";
import {IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {remoteRoutes} from "../../../../../data/constants";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {IProcess} from "../../interfaces/IHub";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {post} from "../../../../../utils/ajax";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {ISystemProduct} from "../../products/interfaces/ISystemProduct";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {ProcessSchema} from "../interfaces/IProcessSchema";
import {useIntl} from "react-intl";

type Props = {
    hubId?: string
    pagination: IPaginationMetadata
}

const CreateProcess = ({hubId, pagination}: Props) => {
    const intl = useIntl();
    const products = useSelector((state: IState) => state.products.data);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const dispatch = useDispatch();
    const auth = useAuth();
    const currentUser = auth.currentUser;

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        name: null,
        products: [],
        costCenters: [],
        description: null
    }

    useEffect(() => {
    }, []);

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, '/processes')
        const data: IProcess = {
            hubId: hubId ?? currentUser?.hubId,
            products: values.products,
            name: values.name,
            description: values.description
        }

        post(url, data, (response) => {

            CloseModal("add-process", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_PROCESSES,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setSelectedProducts([])
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const validationSchema = ProcessSchema(intl)

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({handleSubmit, isSubmitting, touched, errors, values, setFieldValue}) => {
                return <ModalWrapper
                    id="add-process"
                    title={`${intl.formatMessage({id: "FORM.TITLE.ADDPROCESS", defaultMessage: "Add a Process"})}`}
                    submitting={isSubmitting}
                    size="md"
                    key={modalKey}
                    handleSubmit={handleSubmit}
                >

                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({id: "FORM.NAME", defaultMessage: "Name"})}`}
                                name="name"
                            />
                        </div>
                        <div className="col-lg-12 mb-4">
                            <Field name="products">
                                {() => (
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option: ISystemProduct) => option.name}
                                        options={products}
                                        onChange={(event, value) => {
                                            setFieldValue('products', value.map(prod => ({
                                                id: prod.id,
                                                name: prod.name
                                            })));
                                        }}

                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`${intl.formatMessage({
                                                    id: "FORM.SELECTPRODUCT",
                                                    defaultMessage: "Select products"
                                                })}`}
                                                variant="standard"
                                                error={touched.products && Boolean(errors.products)}
                                                helperText={touched.products && errors.products ? errors.products : ''}
                                            />
                                        )}/>
                                )}
                            </Field>
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({id: "FORM.DESCRIPTION", defaultMessage: "Description"})}`}
                                name="description"
                            />
                        </div>
                    </div>

                </ModalWrapper>

            }}
        </Formik>
    );
}

export default CreateProcess;