import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { Field, Formik } from "formik";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { post } from "../../../../utils/ajax";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import moment from "moment";
import { useAuth } from "../../auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { CreateBatchSchema } from "../schemas/CreateBatchSchema";
import { XTextArea } from "../../../components/shared/forms/XTextArea";
import { IOption, XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import {ICollectionCentre, IProduct} from "../../settings/interfaces/IHub";
import { authService } from "../../../../data/oidc/AuthService";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {useIntl} from "react-intl";

type Props = {
    quantity: number
    purchaseIds: any[]
    product: IProduct
    variety?: { id: string; name: string }
}

export const CreateBatchForm = ({ quantity, purchaseIds, product, variety }: Props) => {

    const intl = useIntl();
    const dispatch = useDispatch()
    const { currentUser } = useAuth()
    const hub: any = authService.getHub()
    const navigate = useNavigate()
    const {collectionCentres} = hub
    const locations: IOption = collectionCentres?.map((center: ICollectionCentre) => ({
        label: center.name,
        id: center.id
    }))

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        batchNumber: '',
        location: null,
        product: product,
        description: '',
        varietyId: '',
        variety: variety?.name,
    }

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.inventoryService, '/batches')
        const data = {
            hubId: currentUser?.hubId,
            date: moment().toISOString(),
            unitId: "9ee31032-0f87-462a-bfe5-6561b5cc810d",
            unitName: 'kg',
            productId: values.product.id,
            productName: values.product.name,
            varietyId: variety?.id,
            variety: variety?.name,
            batchingCollectionCentreId: values.location.id,
            batchingCollectionCentreName: values.location.label,
            quantity: quantity,
            purchaseIds: purchaseIds,
            batchNumber: values.batchNumber,
            description: values.description
        }

        post(url, data, (response) => {

            CloseModal("create-batch", () => {
                setSubmitting(false);
                resetForm();
                navigate('/inventory/batches')
            })

            dispatch({
                type: INPUTS_DISTRIBUTION_CONSTANTS.POST_DATA,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());

        }, (error) => {

            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {

    }, [])

    const validationSchema = CreateBatchSchema(intl)
    return (

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}>
            {({ handleSubmit, isSubmitting, values }) => {

                return <ModalWrapper
                    id="create-batch"
                    title={`${intl.formatMessage({ id: "FORM.TITLE.CREATEBATCH", defaultMessage: "Create a Batch" })}`}
                    size="md"
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <Box>
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <Field
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.BATCHNUMBER", defaultMessage: "Batch Number" })}`}
                                    name="batchNumber"
                                />
                            </div>

                            <div className="col-lg-6 col-12">
                                <Field
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.QUANTITY", defaultMessage: "Quantity" })}`}
                                    name="quantity"
                                    value={`${quantity} kg`}
                                    disabled
                                />
                            </div>

                            <div className="col-lg-6 col-12">
                                <Field
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.PRODUCT", defaultMessage: "Product" })}`}
                                    name="product"
                                    value={product.name}
                                    disabled
                                />
                            </div>

                            <div className="col-lg-6 col-12">
                                <Field
                                    as={XTextField}
                                    label={`${intl.formatMessage({ id: "FORM.VARIETY", defaultMessage: "Variety" })}`}
                                    name="variety"
                                    value={variety?.name || 'Product has no variety selected'}
                                    disabled
                                />
                            </div>

                            <div className="col-lg-12 col-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={locations}
                                    label={`${intl.formatMessage({ id: "FORM.WHEREISTHEBATCH", defaultMessage: "Where is the batch?" })}`}
                                    name="location"
                                    value={values.location}
                                />
                            </div>

                            <div className="col-lg-12">
                                <Field
                                    disabled={isSubmitting}
                                    component={XTextArea}
                                    rows={6}
                                    multiline={false}
                                    label={`${intl.formatMessage({ id: "FORM.DESCRIPTION", defaultMessage: "Description" })}`}
                                    name="description"
                                />
                            </div>
                        </div>
                    </Box>
                </ModalWrapper>
            }}
        </Formik>
    )
}

