import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { useAuth } from "../../../auth";
import { remoteRoutes } from "../../../../../data/constants";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { PRODUCTS_CONSTANTS } from "../../../../../data/redux/settings/productsReducer";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { IHubProduct, IProduct } from "../../interfaces/IHub";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { get, post } from "../../../../../utils/ajax";
import { IOption } from "../../../../components/shared/forms/XAutoComplete";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

type Props = {
  hubId?: string;
  items: IProduct[];
};

const AddProductForm = ({ hubId, items }: Props) => {
  const intl = useIntl();

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [products, setProducts] = useState<IOption[]>([]);

    const location = useLocation();
    const { refreshHubDetails } = useAuth();

    const dispatch = useDispatch();
    const auth = useAuth();
    const currentUser = auth.currentUser;
    const userHub = auth.userHub;

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        hubId: hubId ?? currentUser?.hubId,
        products: []
    }

    const productOptions = products?.map(p => ({
        id: p.id,
        name: p.label
    }));

    const defaultProducts = items.map(p => ({
        id: p.id,
        name: p.name
    }));

    useEffect(() => {
        handleFetchAllData()
    }, [dispatch]);

    const handleFetchAllData = () => {
        if (remoteRoutes.onboardingService) {
            let allData: any[] = [];
            let currentPage = 1;
            let hasMore = true;

            const fetchPage = (page: number) => {
                const url = buildUrl(remoteRoutes.onboardingService, `/products?page=${page}`); // Adjust the page param according to your API's pagination

                get(
                    url,
                    (response: any) => {
                        const { data } = response;

                        allData = [...allData, ...data];

                        hasMore = data.length > 0;

                        if (hasMore) {
                            fetchPage(currentPage + 1);
                            currentPage++;
                        } else {
                            dispatch({
                                type: PRODUCTS_CONSTANTS.FETCH_DATA,
                                payload: allData
                            });

                            if (location.pathname.includes("settings")) {
                                const productList = allData?.map((p: any) => ({
                                    id: p.id ?? '',
                                    label: p.name,
                                })).sort((a: { label: string; }, b: { label: any; }) => a.label.localeCompare(b.label));

                                setProducts(productList);
                            } else {
                                const productList = userHub?.products?.map(p => ({
                                    id: p.id ?? '',
                                    label: p.name,
                                })) || [];
                                setProducts(productList);
                            }

                            dispatch({ type: PRODUCTS_CONSTANTS.STOP_FETCH });
                        }
                    },
                    async (error) => {
                        toast.error(toastMessages.default.fail, overrideToastDefaults);
                        dispatch({ type: PRODUCTS_CONSTANTS.STOP_FETCH });
                    },
                    () => {
                        dispatch({ type: PRODUCTS_CONSTANTS.STOP_FETCH });
                    }
                );
            };

            // Start by fetching the first page
            fetchPage(currentPage);
        }
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, '/hubs/products')
        const data: IHubProduct = {
            hubId: values.hubId,
            products: values.products
        }

        post(url, data, (response) => {

            CloseModal("add-product", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: PRODUCTS_CONSTANTS.POST_DATA,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            refreshHubDetails();
            setSelectedProducts([]);
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={{
                ...initialValues,
                products: defaultProducts
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting, touched, errors, values, setFieldValue }) => {
                return <ModalWrapper
                    id="add-product"
                    title={`${intl.formatMessage({ id: "FORM.TITLE.ADDPRODUCTS", defaultMessage: "Add product(s)" })}`}
                    position="top"
                    submitting={isSubmitting}
                    size="md"
                    key={modalKey}
                    handleSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-lg-12">
                            <Field name="products">
                                {() => (
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option) => option.name}
                                        options={productOptions}
                                        onChange={(event, value) => {
                                            setFieldValue('products', value);
                                        }}

                                        value={values.products}

                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`${intl.formatMessage({ id: "FORM.SELECTPRODUCT", defaultMessage: "Select product(s)" })}`}
                                                variant="standard"
                                                error={touched.products && Boolean(errors.products)}
                                            />
                                        )} />
                                )}
                            </Field>

                        </div>
                    </div>

                </ModalWrapper>

            }}
        </Formik>
    );
}

export default AddProductForm;
