import { useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import toast from "react-hot-toast";
import { CollectionCentreSchema } from "../interfaces/CollectionCentreSchema";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { ICollectionCentre } from "../../interfaces/IHub";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { put } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { districts } from "../../../../../data/districts";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { useAuth } from "../../../auth";
import { useIntl } from "react-intl";

interface Props {
  initialValues: ICollectionCentre;
}

const EditCollectionCentreForm = ({ initialValues }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const handleSubmit = (values: any, resetForm: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, `/collection/centres/${values.id}`)
        const data: ICollectionCentre = {
            ...values,
            district: values.district.label,
            hubs: values.hubs ?? [],
            hubId: values.hubId ?? currentUser?.hubId,
            latitude: values.latitude ?? null,
            longitude: values.longitude ?? null,
        }

        put(url, data, (response) => {

            CloseModal("edit-collection-centre")

            dispatch({
                type: SETTINGS_CONSTANTS.UPDATE_COLLECTION_CENTRE,
                payload: data
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {

            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const validationSchema = CollectionCentreSchema(intl)

    return (
        <Formik
            initialValues={{
                ...initialValues,
                district: initialValues.district ? {
                    label: initialValues.district,
                    value: initialValues.district
                } : null,
                latitude: initialValues.latitude !== null && initialValues.latitude !== undefined ? initialValues.latitude : null,
                longitude: initialValues.longitude !== null && initialValues.longitude !== undefined ? initialValues.longitude : null,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>

            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id="edit-collection-centre"
                    title="Edit Collection Centre"
                    size="md"
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.NAME", defaultMessage: "Name" })}`}
                                name="name"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={districts}
                                label={`${intl.formatMessage({ id: "FORM.DISTRICT", defaultMessage: "District" })}`}
                                name="district"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.SUBCOUNTY", defaultMessage: "Sub County" })}`}
                                name="subCounty"
                            />
                        </div>

              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.PARISH", defaultMessage: "Parish" })}`}
                  name="parish"
                />
              </div>

              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.VILLAGE", defaultMessage: "Village" })}`}
                  name="village"
                />
              </div>

              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.LATITUDE", defaultMessage: "Latitude" })}`}
                  name="latitude"
                />
              </div>

              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label={`${intl.formatMessage({ id: "FORM.LONGITUDE", defaultMessage: "Longitude" })}`}
                  name="longitude"
                />
              </div>
            </div>
          </ModalWrapper>
      }}
    </Formik>
  );
};

export default EditCollectionCentreForm;
