import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {
    faBarcode,
    faBriefcase,
    faCartShopping,
    faCogs,
    faDashboard,
    faHelmetSafety,
    faList,
    faMoneyCheckDollar,
    faPeopleGroup,
    faTruck,
    faUsers
} from "@fortawesome/free-solid-svg-icons";

export interface IMenuItem {
    id?: string
    isDivider?: boolean
    isActive?: boolean
    path?: string
    label: string
    icon?: IconProp | undefined
    subMenu?: IMenuItem[]
}

interface MainMenuProps {
    activePage: string;
    mau: string;
}

export const MenuItems = ({activePage, mau}: MainMenuProps): IMenuItem[] => {

    return [
        // {
        //     isActive: activePage.toLowerCase().split("/").includes('dashboard'),
        //     path: '/dashboard',
        //     label: 'Dashboard',
        //     icon: faDashboard,
        //     id: "MENU.MAIN.DASHBOARD"
        // },
        {
            isActive: activePage.toLowerCase().split("/").includes('clients'),
            path: '/clients',
            label: 'Clients',
            icon: faBriefcase,
            id: "MENU.MAIN.CLIENTS"
        },
        {
            isActive: activePage.toLowerCase().split("/").includes('hubs'),
            path: '/hubs',
            label: mau ?? 'test',
            icon: faList,
            id: "MENU.MAIN.HULLERS"
        },
        {
            isActive: activePage.toLowerCase().split("/").includes('farmers'),
            path: '/farmers',
            label: 'Farmers',
            icon: faPeopleGroup,
            id: "MENU.MAIN.FARMERS"
        },
        {
            isActive: activePage.toLowerCase().split("/").includes('inputs'),
            path: '/inputs',
            label: 'Inputs',
            icon: faTruck,
            id: "MENU.MAIN.INPUTS"
        },
        {
            isActive: activePage.toLowerCase().split("/").includes('inventory'),
            path: '/inventory',
            label: 'Inventory',
            icon: faBarcode,
            id: "MENU.MAIN.INVENTORY"
        },
        // {
        //     isActive: activePage.toLowerCase().split("/").includes('contact'),
        //     path: '/orders',
        //     label: 'Orders',
        //     icon: faCartShopping,
        //     id: "MENU.MAIN.ORDERS"
        // },
        // {
        //     isActive: activePage.toLowerCase().split("/").includes('trainings'),
        //     path: '/trainings',
        //     label: 'Trainings',
        //     icon: faHelmetSafety,
        //     id: "MENU.MAIN.TRAININGS"
        // },
        // {
        //     isActive: activePage.toLowerCase().split("/").includes('contact'),
        //     path: '/products',
        //     label: 'Cost Tracking',
        //     icon: faMoneyCheckDollar,
        //     id: "MENU.MAIN.COSTTRACKING"
        // },
        {
            isActive: activePage.toLowerCase().split("/").includes('settings'),
            path: '/settings',
            label: 'Settings',
            icon: faCogs,
            id: "MENU.MAIN.SETTINGS"
        },
        {
            isActive: activePage.toLowerCase().split("/").includes('users'),
            path: '/users',
            label: 'Users',
            icon: faUsers,
            id: "MENU.MAIN.USERS"
        },
    ];
}