import { Formik } from "formik";
import toast from "react-hot-toast";
import { ICostCentre } from "../../interfaces/IHub";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { del } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { IHubInput, IInput } from "../interfaces/IInput";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

interface Props {
  item: IHubInput;
}

const DeleteInputForm = ({ item }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const handleSubmit = () => {
    const url = buildUrl(remoteRoutes.onboardingService, `/inputs/${item.id}`);

    del(
      url,
      (response) => {
        CloseModal("delete-input");
        navigate("/settings");
        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        console.log(error);

        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  return (
    <Formik
      initialValues={item}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id="delete-input"
            title={`${intl.formatMessage({ id: "FORM.TITLE.DELETEINPUT", defaultMessage: "Delete Input" })}`}
            size="md"
            isDeleteAction={true}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>
              Are you sure you want to delete this input? This action cannot be
              undone.
            </p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteInputForm;
