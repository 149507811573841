import {IFarmerProfile} from "../../../app/modules/profiling/interfaces/IFarmerProfile";
import {IBatchMovement} from "../../../app/modules/inventory/interfaces/IBatch";
import {INVENTORY_BATCHES_CONSTANTS} from "./batchesReducer";
import {IPaginationMetadata} from "../../../app/interfaces/IApiResponse";

export const INVENTORY_BATCH_MOVEMENTS_CONSTANTS = {
    DELETE_BATCH_MOVEMENT: "DELETE_BATCH_MOVEMENT",
    FETCH_BATCH_MOVEMENTS: "FETCH_BATCH_MOVEMENTS",
    FILTER_DATA: "FILTER_DATA",
    GET_BATCH_MOVEMENT: "GET_BATCH_MOVEMENT",
    LOADING_BATCH_MOVEMENTS: "LOADING_BATCH_MOVEMENTS",
    PAGINATION: "PAGINATION",
    POST_BATCH_MOVEMENT: "POST_BATCH_MOVEMENT",
    RESET_FILTER: "RESET_FILTER",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    STOP_FETCH: "STOP_FETCH",
}

export interface IBatchMovementState {
    loading: boolean
    data: IBatchMovement[]
    pagination: IPaginationMetadata
    filter: {
        show: boolean,
        data: IBatchMovement[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IBatchMovementState = {
    loading: false,
    data: [],
    pagination: {
        currentPage: 1,
        pageSize: 15,
        hasPreviousPage: false,
        hasNextPage: false,
        totalItems: 0,
        totalPages: 0
    },
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function batchMovementsReducer(state = initialState, action: any) {
    switch (action.type) {

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.FILTER_DATA:
            return {...state, ...action.payload, show: !state.filter.show}

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return {...initialState, reset: true}

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.SET_SORT_PARAMS:
            return {...state, ...action.payload}

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.PAGINATION:
            return {...state, ...action.payload}

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.FETCH_BATCH_MOVEMENTS: {
            return {...state, loading: false, data: action.payload, reset: false}
        }

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.POST_BATCH_MOVEMENT: {
            return {...state, data: [action.payload, ...state.data]}
        }

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.DELETE_BATCH_MOVEMENT: {
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state,
                data: state.data.filter(batch => batch.id !== action.payload.id),
                pagination
            }
        }

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.LOADING_BATCH_MOVEMENTS: {
            return {...state, loading: action.payload}
        }

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.GET_BATCH_MOVEMENT: {
            return {...state, requestDetails: action.payload}
        }

        case INVENTORY_BATCH_MOVEMENTS_CONSTANTS.STOP_FETCH: {
            return {...state, loading: false}
        }

        default: {
            return state
        }
    }
}
