import { Field, Formik, useField } from "formik";
import toast from "react-hot-toast";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { del, post } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { IProcessDto } from "../interfaces/IProcessDto";
import { IProcess } from "../../interfaces/IHub";
import { useIntl } from "react-intl";

interface Props {
  item: IProcess;
}

const DeleteProcessForm = ({ item }: Props) => {
  const intl = useIntl();

  const handleSubmit = () => {
    const url = buildUrl(
      remoteRoutes.onboardingService,
      `/processes/${item.id}`,
    );

    del(
      url,
      (response) => {
        CloseModal("delete-process");

        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        console.log(error);

        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  return (
    <Formik
      initialValues={item}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id="delete-process"
            title={`${intl.formatMessage({ id: "FORM.TITLE.DELETEPROCESS", defaultMessage: "Delete Process" })}`}
            size="md"
            isDeleteAction={true}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>
              Are you sure you want to delete this process? This action cannot
              be undone.
            </p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteProcessForm;
