import { XSidebarWrapper } from "../../../components/shared/XSidebarWrapper";
import React from "react";
import { IHeaderButtonProps } from "../../../components/shared/SettingsPageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faTrash } from "@fortawesome/free-solid-svg-icons";
import { IUser } from "./interfaces/IUser";
import { FormattedMessage, useIntl } from "react-intl";
import {IPaginationMetadata} from "../../../interfaces/IApiResponse";
import DeleteUserForm from "./modals/DeleteUserForm";

type Props = {
  close: () => void;
  show: boolean;
  user: IUser;
  pagination: IPaginationMetadata;
};

const UserDetails = ({close, show, user, pagination}: Props) => {
  const intl = useIntl();

  const actionButtons: IHeaderButtonProps[] = [
    // {
    //     label: `${intl.formatMessage({ id: "BUTTONS.EDIT", defaultMessage: "Edit" })}`,
    //     cssClass: "btn btn-primary btn-sm me-1",
    //     dataTarget: "#edit-user",
    //     dataToggle: "modal"
    // },
    {
      cssClass: "btn btn-danger btn-sm",
      dataTarget: "#delete-user",
      dataToggle: "modal",
      deleteIcon: true
    },
  ];

  return (
    <>
      <XSidebarWrapper
        title={`${intl.formatMessage({ id: "DETAILS.TITLE.USERDETAILS", defaultMessage: "User Details" })}`}
        width={35}
        close={close}
        show={show}
      >
        <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
          <div className="py-3 px-4 rounded-1 bg-primary">
            <h2>
              {user?.firstName[0]}
              {user?.lastName[0]}
            </h2>
          </div>
          <div className="py-3 w-100">
            <h5>
              {user?.firstName} {user?.lastName}
            </h5>
            <div className="d-flex justify-content-between w-100">
              <div className="fs-6 small d-flex justify-content-between align-items-center gap-2">
                <FontAwesomeIcon icon={faPhone} />
                <span>{user?.phoneNumber}</span>
              </div>

              <div className="action-buttons">
                {actionButtons.map((button, index) => {
                  return (
                    <button
                      data-bs-toggle={button.dataToggle}
                      data-bs-target={button.dataTarget}
                      className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                      key={index}
                      onClick={button.onClick}
                    >
                      {button.deleteIcon && <FontAwesomeIcon icon={faTrash} />}
                      {button.processing
                        ? `${intl.formatMessage({
                            id: "LOADERS.PLEASEWAIT",
                            defaultMessage: "Please wait...",
                          })}`
                        : button.label}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 bg-gray rounded-3 mb-3">
          <div className="row">
            <div className="col-lg-12 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage id="DETAILS.EMAIL" defaultMessage="Email" />
              </strong>
              <br />
              <span>{user.email}</span>
            </div>
            <div className="col-lg-12 col-12 mb-4">
              <strong className="small fw-semibold">
                <FormattedMessage
                  id="DETAILS.USERNAME"
                  defaultMessage="Username"
                />
              </strong>
              <br />
              <span>{user.userName}</span>
            </div>
          </div>
        </div>
      </XSidebarWrapper>

      <DeleteUserForm id={user?.id} close={close} pagination={pagination}/>
    </>
  );
};

export default UserDetails;
