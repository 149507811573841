import { Formik } from "formik";
import toast from "react-hot-toast";
import { ICostCentre } from "../../interfaces/IHub";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { del } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { useIntl } from "react-intl";

interface Props {
  item: ICostCentre;
}

const DeleteCostCentreForm = ({ item }: Props) => {
  const intl = useIntl();

  const handleSubmit = () => {
    const url = buildUrl(
      remoteRoutes.onboardingService,
      `/cost/centres/${item.id}`,
    );

    del(
      url,
      (response) => {
        CloseModal("delete-cost-centre");
        window.location.reload()

        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {

        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  return (
    <Formik
      initialValues={item}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id="delete-cost-centre"
            title={`${intl.formatMessage({ id: "FORM.TITLE.DELETECOSTCENTRE", defaultMessage: "Delete Cost Centre" })}`}
            size="md"
            isDeleteAction={true}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>
              Are you sure you want to delete this cost centre? This action
              cannot be undone.
            </p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteCostCentreForm;
