import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import { IState } from "../../../data/types";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import DashboardLoader from "../../components/shared/loaders/DashboardLoader";
import { IPurchaseState } from "../../../data/redux/inventory/purchasesReducer";
import { InventoryVersusSalesGraph } from "./graphs/InventoryVersusSalesGraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faFilter,
  faShop,
  faMoneyBill,
  faClipboardCheck,
  faBoxArchive,
  faSeedling,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import InventoryStockProgress from "./graphs/InventoryStockProgress";
import InventorySalesProgress from "./graphs/InventorySalesProgress";
import XSummaryCard from "../../components/shared/XSummaryCard";
import { IHub, IProduct } from "../settings/interfaces/IHub";
import { authService } from "../../../data/oidc/AuthService";
import { InventoryCostsVersusRevenueGraph } from "./graphs/InventoryCostsVersusRevenueGraph";
import { useAuth } from "../auth";
import { remoteRoutes } from "../../../data/constants";
import { buildUrl } from "../../../utils/queryBuilder";
import { get } from "../../../utils/ajax";
import toast from "react-hot-toast";
import { INVENTORY_BATCHES_CONSTANTS } from "../../../data/redux/inventory/batchesReducer";
import {
  toastMessages,
  overrideToastDefaults,
} from "../../../data/toastDefaults";
import DashboardFilters from "./modals/DashboardFilters";
import { IOption } from "../../components/shared/forms/XAutoComplete";
import { printNaturalDateShort } from "../../../utils/dateHelpers";

export const Dashboard = () => {
  document.title = "Inventory > Dashboard";

  const { loading }: IPurchaseState = useSelector(
    (state: IState) => state.purchases,
  );

  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [stockTurnoverRate, setStockTurnoverRate] = useState<number | 0>(0);
  const [daysOnHand, setDaysOnHand] = useState<number | 0>(0);
  const [stockTurnoverRateDescription, setStockTurnoverRateDescription] =
    useState<string | null>(null);
  const [salesAmount, setSalesAmount] = useState<number | 0>(0);
  const [salesMonth, setSalesMonth] = useState<string | null>(null);
  const [salesVolume, setSalesVolume] = useState<number | 0>(0);
  const [daysOnHandDescription, setDaysOnHandDescription] = useState<
    string | null
  >(null);
  const [products, setProducts] = useState<any[] | undefined>(undefined);
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null,
  );
  const [initialProductSet, setInitialProductSet] = useState(false);
  const dispatch = useDispatch();
  const [productId, setProductId] = useState<string | null | undefined>(
    undefined,
  );
  const [varietyId, setVarietyId] = useState<string | null | undefined>(
    undefined,
  );
  const [collectionCentre, setCollectionCentre] = useState<
    IOption | null | undefined
  >(null);
  const [startDate, setStartDate] = useState<string | null | undefined>(
    undefined,
  );
  const [endDate, setEndDate] = useState<string | null | undefined>(undefined);

  const { currentUser } = useAuth();

  const hub: IHub | undefined = authService.getHub();
  useEffect(() => {
    if (hub) {
      const { products } = hub;
      const sortedProducts = products
        ?.map((product: IProduct) => ({
          id: product.id,
          label: product.name,
          varieties:
            product.varieties?.map((variety) => ({
              id: variety.id,
              label: variety.name,
            })) || [],
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setProducts(sortedProducts || []);
    }
  }, [hub]);

  useEffect(() => {
    if (products && products.length > 0 && !initialProductSet) {
      setSelectedItem(products[0].label);
      setSelectedProductId(products[0].id);
      setInitialProductSet(true);
    }
  }, [products, initialProductSet]);

  const setLoading = (status: boolean) => {
    dispatch({
      type: INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES,
      payload: status,
    });
  };

  const showStatistics = () => {
    let params: any = {
      hubId: currentUser?.hubId,
    };

    if (productId) {
      params.productId = productId;
    }

    if (collectionCentre?.id) {
      params.collectionCentreId = collectionCentre.id;
    }

    if (varietyId) {
      params.varietyId = varietyId;
    }

    if (startDate) {
      params.startDate = startDate;
    }

    if (endDate) {
      params.endDate = endDate;
    }

    const turnoverUrl = buildUrl(
      remoteRoutes.inventoryService,
      "/dashboard/turnover",
      params,
    );

    const salesAmountUrl = buildUrl(
      remoteRoutes.inventoryService,
        "/dashboard/sales-amount",
      params,
    );

    const daysOnHandUrl = buildUrl(
      remoteRoutes.inventoryService,
        "/dashboard/days-at-hand",
      params,
    );

    get(
      turnoverUrl,
      (response) => {
        const turnoverRate = response?.value ?? 0;
        const turnoverRateDescription = response?.description ?? null;

        dispatch({
          type: INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES,
          payload: response,
        });

        setStockTurnoverRate(turnoverRate);
        setStockTurnoverRateDescription(turnoverRateDescription);
      },
      (error) => {
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {
        dispatch({ type: INVENTORY_BATCHES_CONSTANTS.STOP_FETCH });
        setLoading(false);
      },
    );

    get(
      salesAmountUrl,
      (response) => {
        const salesMonth = response?.month ?? null;
        const salesVolume = response?.volume ?? 0;
        const salesAmount = response?.sales ?? 0;

        dispatch({
          type: INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES,
          payload: response,
        });

        setSalesMonth(salesMonth);
        setSalesVolume(salesVolume);
        setSalesAmount(salesAmount);
        console.log("Sales Amount:", salesAmount);
        console.log("Sales Response:", response);
      },
      (error) => {
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {
        dispatch({ type: INVENTORY_BATCHES_CONSTANTS.STOP_FETCH });
        setLoading(false);
      },
    );

    get(
      daysOnHandUrl,
      (response) => {
        const daysAtHand = response?.value ?? 0;
        const daysAtHandDescription = response?.description ?? null;

        dispatch({
          type: INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES,
          payload: response,
        });

        setDaysOnHand(daysAtHand);
        setDaysOnHandDescription(daysAtHandDescription);
      },
      (error) => {
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {
        dispatch({ type: INVENTORY_BATCHES_CONSTANTS.STOP_FETCH });
        setLoading(false);
      },
    );
  };

  const getDateDisplayText = () => {
    if (startDate && endDate) {
      return `From ${printNaturalDateShort(startDate)} - ${printNaturalDateShort(endDate)}`;
    } else if (startDate) {
      return `From ${printNaturalDateShort(startDate)} - Today`;
    } else if (endDate) {
      const beginningOfYear = new Date(new Date().getFullYear(), 0, 1);
      return `From ${printNaturalDateShort(beginningOfYear)} - ${printNaturalDateShort(endDate)}`;
    } else {
      return "This Year";
    }
  };

  useEffect(() => {
    if (currentUser?.hubId) {
      showStatistics();
    }
  }, [currentUser, productId, collectionCentre, varietyId, startDate, endDate]);

  return (
    <>
      {loading && (
        <>
          <PageHeaderLoader />
          <DashboardLoader />
        </>
      )}

      {!loading && (
        <>
          <div className={"py-3"}>
            <InnerMenu module={Modules.Inventory} />
          </div>

          <div className="row mb-3">
            <div className="col-12">
              <div className="card border-0 shadow">
                <div className="card-body d-flex w-100 justify-content-between">
                  <div className="d-flex justify-content-start align-items-center w-75">
                    <div className="bg-gray ps-3 pe-3 pt-1 pb-1 rounded-5 me-2">
                      <FontAwesomeIcon
                        icon={faSeedling}
                        size={"sm"}
                        color={"#1F5A2D"}
                      />
                      <span className={"p-1"}>
                        {productId
                          ? products?.find(
                              (product) => product.id === productId,
                            )?.label
                          : "All Products"}
                      </span>
                    </div>
                    {varietyId ? (
                      <div className="bg-gray ps-3 pe-3 pt-1 pb-1 rounded-5 me-2">
                        <FontAwesomeIcon
                          icon={faLeaf}
                          size={"sm"}
                          color={"#1F5A2D"}
                        />
                        <span className={"p-1"}>
                          {
                            products
                              ?.find((product) => product.id === productId)
                              ?.varieties.find(
                                (v: { id: string }) => v.id === varietyId,
                              )?.label
                          }
                        </span>
                      </div>
                    ) : null}
                    <div className="bg-gray ps-3 pe-3 pt-1 pb-1 rounded-5 me-2">
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        size={"sm"}
                        color={"#1F5A2D"}
                      />
                      <span className={"p-1"}>{getDateDisplayText()}</span>
                    </div>
                    <div className="bg-gray ps-3 pe-3 pt-1 pb-1 rounded-5 me-2">
                      <FontAwesomeIcon
                        icon={faShop}
                        size={"sm"}
                        color={"#1F5A2D"}
                      />
                      <span className={"p-1"}>
                        {collectionCentre?.id
                          ? collectionCentre?.label
                          : "All Collection Centers"}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end align-items-center w-25">
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle={"modal"}
                      data-bs-target={"#inventory-dashboard-filters"}
                    >
                      <FontAwesomeIcon
                        icon={faFilter}
                        color={"#1F5A2D"}
                        className="me-2"
                      />
                      Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-4">
              <XSummaryCard
                bgColor="#1C5838"
                textColor="white"
                iconBgColor="white"
                iconColor="orange"
                isLoading={loading}
                label={"Amount from Sales"}
                value={String(salesAmount ?? "0")}
                icon={faMoneyBill}
              />
            </div>
            <div className="col-4" title={String(stockTurnoverRateDescription)}>
              <XSummaryCard
                bgColor="#1C5838"
                textColor="white"
                iconBgColor="white"
                iconColor="orange"
                isLoading={loading}
                label={"Stock turnover rate"}
                value={String(stockTurnoverRate ?? "0")}
                icon={faClipboardCheck}
              />
            </div>
            <div className="col-4" title={String(daysOnHandDescription)}>
              <XSummaryCard
                bgColor="#1C5838"
                textColor="white"
                iconBgColor="white"
                iconColor="orange"
                isLoading={loading}
                label={"Days of Inventory at hand"}
                value={String(daysOnHand ?? "0")}
                icon={faBoxArchive}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="card w-100 border-0 mb-3">
                <div className="card-body" style={{ minHeight: 150 }}>
                  <h6>Product Varieties in stock</h6>
                  <InventoryStockProgress
                    productId={productId}
                    collectionCentreId={collectionCentre?.id}
                    varietyId={varietyId}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              </div>
              <div className="card w-100 border-0 mb-3">
                <div className="card-body">
                  <h6>
                    {collectionCentre?.id
                      ? `Product varieties’ stock levels at ${collectionCentre.label}`
                      : "Product varieties’ stock levels at all Collection Centers"}
                  </h6>
                  <InventorySalesProgress
                    productId={productId}
                    collectionCentreId={collectionCentre?.id}
                    varietyId={varietyId}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div
                    className="col card border-0"
                    style={{ height: 445, borderRadius: 12 }}
                  >
                    <div className="card-body">
                      <h6>Purchases Vs Sales</h6>
                      <InventoryCostsVersusRevenueGraph
                        productId={productId}
                        collectionCentreId={collectionCentre?.id}
                        varietyId={varietyId}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div
                    className="col card border-0"
                    style={{ height: 445, borderRadius: 12 }}
                  >
                    <div className="card-body">
                      <h6>Purchases Vs Sales</h6>
                      <InventoryVersusSalesGraph
                        productId={productId}
                        collectionCentreId={collectionCentre?.id}
                        varietyId={varietyId}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <DashboardFilters
        setProductId={setProductId}
        setVarietyId={setVarietyId}
        setCollectionCentre={setCollectionCentre}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </>
  );
};
