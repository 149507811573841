import {useDispatch} from "react-redux";
import {ErrorMessage, Field, Formik} from "formik";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {post} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {IUser, UserSchema} from "../interfaces/IUser";
import {IOption, XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";
import {InputAdornment} from "@mui/material";
import {IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {useIntl} from "react-intl";

type Props = {
    hubId?: string
    hubName?: string
    pagination: IPaginationMetadata
}

const CreateNewUserForm = ({hubId, hubName, pagination}: Props) => {
    const intl = useIntl();

    const dispatch = useDispatch();

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
        confirmPassword: "",
        additionalClaims: []
    }

    const handleSubmit = async (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.authServer, '/api/v1/users');
        const data: IUser = {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: `256${values.phoneNumber}`,
            email: values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
            additionalClaims: [
                {key: "hubId", value: hubId},
                {key: "hubName", value: hubName ?? ''},
                {key: "role", value: values.role.id ?? ''}
            ]
        };

        post(url, data, (response) => {

            CloseModal("new-user", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_USERS,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    };

    useEffect(() => {
    }, []);

    const roles: IOption[] = [
        {id: "Agent", label: "Agent"},
        {id: "Batch Data Admin", label: "Batch Data Admin"},
        {id: "Hub Administrator", label: "Hub Administrator"},
        {id: "Merchant", label: "Merchant"},
    ]

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const value = event.target.value;
        if (value.length <= 9) {
            setFieldValue("phoneNumber", value);
        }
    };

    const validationSchema = UserSchema(intl)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting, setFieldValue, values}) => {
                return (
                    <ModalWrapper
                        id="new-user"
                        title={`${intl.formatMessage({id: "FORM.TITLE.ADDNEWUSER", defaultMessage: "Add a new user"})}`}
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}
                    >
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.FIRSTNAME",
                                        defaultMessage: "First Name"
                                    })}`}
                                    name="firstName"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({id: "FORM.LASTNAME", defaultMessage: "Last Name"})}`}
                                    name="lastName"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={roles}
                                    label={`${intl.formatMessage({id: "FORM.ROLE", defaultMessage: "Role"})}`}
                                    name="role"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.PHONENUMBER",
                                        defaultMessage: "Phone number"
                                    })}`}
                                    name="phoneNumber"
                                    inputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">+256</InputAdornment>
                                        ),
                                        maxLength: 9,
                                        onKeyPress: handleKeyPress,
                                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange(event, setFieldValue),
                                    }}
                                />
                                <ErrorMessage name="PhoneNumber" component="div"/>
                            </div>

                            <div className="col-lg-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({id: "FORM.EMAIL", defaultMessage: "Email"})}`}
                                    name="email"
                                />
                                <ErrorMessage name="email" component="div"/>
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"password"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({id: "FORM.PASSWORD", defaultMessage: "Password"})}`}
                                    name="password"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"password"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.CONFIRMPASSWORD",
                                        defaultMessage: "Confirm Password"
                                    })}`}
                                    name="confirmPassword"
                                />
                            </div>
                        </div>
                    </ModalWrapper>
                );
            }}
        </Formik>
    );
};

export default CreateNewUserForm;