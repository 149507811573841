import {useDispatch} from "react-redux";
import {Field, Formik} from "formik";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {get, post} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {districts} from "../../../../../data/districts";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";
import {useAuth} from "../../../auth";
import {HUBS_CONSTANTS} from "../../../../../data/redux/settings/hubsReducer";
import {HubSchema, IHubDto} from "../interfaces/IHubDto";
import {IHub, IInputCategory} from "../../../settings/interfaces/IHub";
import {IApiResponse, IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {InputAdornment} from "@mui/material";
import {authService} from "../../../../../data/oidc/AuthService";
import {removeTrailingS} from "../../../../hooks/mauNormalizer";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

type Props = {
    hubId?: string
    pagination: IPaginationMetadata
}
const CreateHubForm = ({hubId, pagination}: Props) => {
    const intl = useIntl();
    const urlParams = useParams();

    const dispatch = useDispatch();
    const {currentUser} = useAuth();
    const hub: IHub | any = authService.getHub()
    const [hubDetails, setHubDetails] = useState<IHub>({});
    const mau: string | undefined = hubDetails.minimumAggregationUnit !== "" ? hubDetails.minimumAggregationUnit : hub.minimumAggregationUnit;

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        name: "",
        email: "",
        phoneNumber: "",
        district: null,
        parentHubId: "",
        subCounty: "",
        village: "",
        postalAddress: "",
        minimumAggregationUnit: "",
    };

    const [hubs, setHubs] = useState<IHub[]>([]);

    const hubOptions = hubs?.map((hub: IHub) => ({
        label: hub.name,
        id: hub.id,
    }));

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, '/hubs')
        const data: IHubDto = {
            name: values.name,
            email: values.email,
            phoneNumber: `+256${values.phoneNumber}`,
            district: values.district.label,
            parentHubId: urlParams.hubId ?? currentUser?.hubId,
            subCounty: values.subCounty,
            village: values.village,
            postalAddress: values.postalAddress,
            minimumAggregationUnit: values.minimumAggregationUnit,
        }

        post(url, data, (response) => {

            CloseModal("new-hub", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: HUBS_CONSTANTS.POST_DATA,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
        handleFetchData(1, 1000)
    }, [hubId]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            const parentHubId = currentUser?.hubId;
            let params: any = {parentHubId, page, pageSize};

            // Fetch hubs
            const hubsUrl = buildUrl(remoteRoutes.onboardingService, '/hubs', params);
            get(hubsUrl,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;
                    setHubs(data);
                    // toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: HUBS_CONSTANTS.STOP_FETCH});
                });

            // Fetch MAU (Minimum Aggregation Unit)
            const NewId = urlParams.hubId ?? currentUser?.hubId;
            const hubDetailsUrl = buildUrl(remoteRoutes.onboardingService, `/hubs/${NewId}`);
            get(hubDetailsUrl,
                (response: IHub) => {
                    setHubDetails(response);
                    dispatch({
                        type: HUBS_CONSTANTS.GET_DETAILS,
                        payload: response
                    });
                },
                () => {
                    // Handle error if needed
                },
                () => {
                    dispatch({type: HUBS_CONSTANTS.STOP_FETCH});
                });
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const value = event.target.value;
        if (value.length <= 9) {
            setFieldValue("phoneNumber", value);
        }
    };

    const validationSchema = HubSchema(intl);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting, setFieldValue}) => {
                return (
                    <ModalWrapper
                        id="new-hub"
                        title={`Create a new ${removeTrailingS(mau)}`}
                        size="lg"
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}
                    >
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({id: "FORM.NAME", defaultMessage: "Name"})}`}
                                    name="name"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.MINIMUMAGGREGATIONUNIT",
                                        defaultMessage: "Minimum Aggregation Unit"
                                    })}`}
                                    name="minimumAggregationUnit"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.PHONENUMBER",
                                        defaultMessage: "Phone number"
                                    })}`}
                                    name="phoneNumber"
                                    inputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">+256</InputAdornment>
                                        ),
                                        maxLength: 9,
                                        onKeyPress: handleKeyPress,
                                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange(event, setFieldValue),
                                    }}
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({id: "FORM.EMAIL", defaultMessage: "Email"})}`}
                                    name="email"
                                />
                            </div>
                            <div className="col-lg-3">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={districts}
                                    label={`${intl.formatMessage({id: "FORM.DISTRICT", defaultMessage: "District"})}`}
                                    name="district"
                                />
                            </div>

                            <div className="col-lg-3">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.SUBCOUNTY",
                                        defaultMessage: "Sub county"
                                    })}`}
                                    name="Sub-county"
                                />
                            </div>

                            <div className="col-lg-3">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({id: "FORM.PARISH", defaultMessage: "Parish"})}`}
                                    name="parish"
                                />
                            </div>

                            <div className="col-lg-3">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({id: "FORM.VILLAGE", defaultMessage: "Village"})}`}
                                    name="village"
                                />
                            </div>
                        </div>
                    </ModalWrapper>
                );
            }}
        </Formik>
    );
};

export default CreateHubForm;