import React, { MouseEventHandler } from 'react';
import { TableLoader } from "./loaders/TableLoader";
import { printDateLong } from "../../../utils/dateHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    printBatchStatus,
    printMovementStatus,
    printDeforestationStatus,
    printComplianceStatus
} from "../../../utils/enumHelpers";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {useIntl} from "react-intl";

export interface ITableColumn {
    localeId?: string
    label: string
    id: string
    link?: string
    isDate?: boolean
    isNumberFormat?: boolean
    textAlign?: 'text-start' | 'text-center' | 'text-end'
    formatter?: (value: any) => string;
}

export interface IRowAction {
    label: string
    icon: IconProp | undefined
    onClick: (item: any) => void
    dataTarget?: string
    dataToggle?: string
}

export interface ITableData {
    actions?: MouseEventHandler<HTMLButtonElement>
    rows: any[]
}

interface IProps {
    checked?: boolean
    data: any[]
    columns: ITableColumn[]
    loading: boolean
    loadingMore?: boolean
    rowActions?: IRowAction[]
    onRowClick?: (row: any) => void
    onCheckRow?: (item: any) => void
    onSelectAll?: (rows: any[]) => void
    // onSelectRow: (row: any) => void
    selected?: any[] | undefined
    children?: any
}


export const XTable = ({
    columns,
    data,
    checked = true,
    selected,
    loading,
    onRowClick,
    onCheckRow,
    onSelectAll,
    rowActions,
    children
}: IProps) => {

    const navigate = useNavigate()
    const intl = useIntl()

    const location = useLocation();
    return (
        <>
            {loading && <>
                <TableLoader />
            </>}

            {!loading && <>
                <div className="border-top mt-3 mb-2 pt-3 pb-0">
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                {checked && <th className="align-middle">
                                    <div className="form-check">
                                        {data?.length > 0 && (
                                            <input
                                                onChange={() => onSelectAll ? onSelectAll(data) : ''}
                                                checked={selected?.length === data?.length}
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="checkAll"
                                            />
                                        )}
                                    </div>
                                </th>}
                                {columns.map((column, index) => {
                                    return (
                                        <th className={`text-muted fw-normal align-middle ${column.textAlign}`}
                                            key={index}>{intl.formatMessage({id: column.localeId, defaultMessage: column.label})}</th>
                                    )
                                })}
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>

                            {!loading && data?.length === 0 && <tr>
                                <td colSpan={columns.length + 2}
                                    className={"text-center text-muted small align-middle"}>No records
                                    found
                                </td>
                            </tr>}

                            {!loading && data?.length > 0 &&
                                data?.map((item: any, index: number) => {

                                    const isItemSelected: boolean = selected ? selected?.indexOf(item.id) !== -1 : false;

                                    return (
                                        <tr className={isItemSelected ? 'selected' : ''} key={index}>
                                            {checked && <td className={'align-middle'}>
                                                <div className="form-check">
                                                    <input className="form-check-input"
                                                        onChange={() => onCheckRow ? onCheckRow(item) : ''}
                                                        type="checkbox" value=""
                                                        checked={isItemSelected}
                                                        id={`check-${index}`} />
                                                </div>
                                            </td>}

                                            {columns.map((column, i) => {
                                                let content;
                                                if (column.isNumberFormat) {
                                                    content = Number(item[column.id]).toLocaleString();
                                                } else if (column.isDate) {
                                                    content = printDateLong(item[column.id], true);
                                                } else if (column.link) {
                                                    content = <a onClick={() => navigate(`${column.link}/${item.id}`)}
                                                        href={"#"}>{item[column.id]}</a>;
                                                } else {
                                                    content = item[column.id];
                                                }

                                                // Additional status check condition
                                                if (column.label.toLowerCase().includes('status') && !location.pathname.includes("inputs/movements") && !location.pathname.includes("search") && !(location.pathname === "/inventory" || location.pathname === "/inventory/stock")) {
                                                    content = printBatchStatus(item[column.id]);
                                                } else if (column.label.toLowerCase().includes('status') && location.pathname.includes("inputs/movements")) {
                                                    content = printMovementStatus(item[column.id]);
                                                } else if (column.label.toLowerCase().includes('status') && location.pathname.includes("search")) {
                                                    content = printDeforestationStatus(item[column.id]);
                                                } else if (column.label.toLowerCase().includes('status') &&
                                                    (location.pathname.includes("/inventory") ||
                                                        location.pathname.includes("farmers"))) {
                                                    content = printComplianceStatus(item[column.id]);
                                                }

                                                return (
                                                    <td onClick={() => onRowClick ? onRowClick(item) : null}
                                                        className={`clickable-row align-middle ${column.textAlign}`}
                                                        key={i}>
                                                        {content}
                                                    </td>
                                                );
                                            })}

                                            <td className={"text-center align-middle"}>
                                                {rowActions && rowActions.length > 0 && <>
                                                    <a className="text-dark text-opacity-50" href="#"
                                                        id={`navbarDropdown${index}`}
                                                        role="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <FontAwesomeIcon icon={faEllipsisV} />
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-menu-end shadow border-0"
                                                        aria-labelledby={`navbarDropdown${index}`}>

                                                        {rowActions.map((action, actionIndex) => {
                                                            return (
                                                                <li>
                                                                    <a className="dropdown-item" href={item.id} key={index}
                                                                        data-bs-toggle={action.dataToggle}
                                                                        data-bs-target={action.dataTarget}
                                                                        onClick={() => action.onClick(item)}>
                                                                        {action.label}
                                                                    </a>

                                                                </li>
                                                            )
                                                        })}

                                                    </ul>
                                                </>}
                                            </td>


                                        </tr>
                                    )
                                })
                            }

                        </tbody>

                    </table>
                </div>
                {children}
            </>}

        </>
    );
}

export default XTable;