import React, {useState} from 'react'
import {IPaginationMetadata} from "../../../interfaces/IApiResponse";
import {useDispatch} from "react-redux";
import {buildUrl} from "../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../data/constants";
import {del} from "../../../../utils/ajax";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {Formik} from "formik";
import {IBatchMovement} from "../interfaces/IBatch";
import {INVENTORY_BATCH_MOVEMENTS_CONSTANTS} from "../../../../data/redux/inventory/batchMovementsReducer";

type Props = {
    id: string | undefined | null
    pagination: IPaginationMetadata
    close: () => void;
}

const DeleteBatchMovementForm = ({id, pagination, close}: Props) => {

    const dispatch = useDispatch()

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.inventoryService, `/movements/${id}`)

        del(url, (response) => {

            dispatch({
                type: INVENTORY_BATCH_MOVEMENTS_CONSTANTS.DELETE_BATCH_MOVEMENT,
                payload: {
                    id, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems - 1,
                    }
                }
            })

            CloseModal("delete-batch-movement", () => {
                setSubmitting(false);
                resetForm();
            });

            close();

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const initialValues: IBatchMovement = {
        batchId: "",
        batchNumber: "",
        batchingCollectionCentreName: "",
        confirmedQuantity: undefined,
        confirmedUnitId: null,
        confirmedUnitName: "",
        currentCollectionCentreName: "",
        currentStatus: 0,
        date: "",
        description: "",
        destinationAgentId: "",
        destinationAgentName: "",
        destinationCollectionCentreId: "",
        destinationCollectionCentreName: "",
        driver: "",
        hubId: "",
        id: "",
        productName: "",
        quantity: 0,
        remarks: "",
        sourceAgentId: "",
        sourceAgentName: "",
        sourceCollectionCentreId: "",
        sourceCollectionCentreName: "",
        typeOfMovement: 0,
        unitName: "",
        vehicleNumber: ""
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="delete-batch-movement"
                    title="Delete Batch Movement"
                    size="md"
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    key={modalKey}
                    handleSubmit={handleSubmit}>
                    <p>Are you sure you want to delete this movement? This action cannot be undone.</p>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default DeleteBatchMovementForm