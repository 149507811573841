import React from "react";
import {XSidebarWrapper} from "../../components/shared/XSidebarWrapper";
import {IPurchase} from "./interfaces/IPurchase";
import PolygonMapComponent from "../../components/shared/loaders/PolygonMapComponent";
import {env} from "../../../data/constants";
import {LoadScript} from "@react-google-maps/api";
import {printComplianceStatus} from "../../../utils/enumHelpers";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import DeletePurchaseForm from "./modals/DeletePurchaseForm";
import {IPaginationMetadata} from "../../interfaces/IApiResponse";

type Props = {
    close: () => void;
    show: boolean;
    purchase: IPurchase | null;
    pagination: IPaginationMetadata
};

const PurchaseDetails = ({close, show, purchase, pagination}: Props) => {
    const key = env.googleMapsApiKey ?? '';

    if (!purchase) return null;

    const farmPolygonDrawing = purchase.farmCompliance?.farmPolygonDrawing ?? [];

    return (
        <>
            <XSidebarWrapper title={"Purchase Details"} width={50} close={close} show={show}>
                <div className="d-flex justify-content-between w-100 align-items-baseline">
                    <div className="d-flex justify-content-start w-100 align-items-center">
                        <div className="py-3 px-3 rounded-1 bg-primary">
                            <h2>
                                {purchase?.productName &&
                                    purchase?.productName[0].toUpperCase()}
                            </h2>
                        </div>
                        <div className="px-3">
                            <h5>{purchase?.productName} - {purchase?.varietyName}</h5>
                            <div className="fs-6 small">
                          <span className="badge text-bg-purple-light">
                            {Number(purchase?.quantity).toLocaleString() +
                                " " +
                                purchase?.unitName}
                          </span>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <button
                            className="btn btn-danger"
                            data-bs-toggle={"modal"}
                            data-bs-target={"#delete-purchase"}>
                            <FontAwesomeIcon icon={faTrashCan}/>
                        </button>
                    </div>
                </div>

                <hr/>

                <div className="scrollable-sidebar">
                    <div className="p-3 bg-gray rounded-3 mb-2">
                        <div className="row">
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Date of Purchase</strong><br/>
                                <span>{purchase.date || "-"}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Farmer Name</strong><br/>
                                <span>{purchase.farmerName || "-"}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Unit Price</strong><br/>
                                <span>UGX {purchase.unitPrice || "-"}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Total Price</strong><br/>
                                <span>UGX {Number(purchase.totalPrice).toLocaleString() || "-"}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Farm Code</strong><br/>
                                <span>{purchase.farmCode || "-"}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Status</strong><br/>
                                <span>{printComplianceStatus(purchase?.farmCompliance?.status)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray border-1 rounded-2 pb-3">
                        <LoadScript googleMapsApiKey={key}>
                            <PolygonMapComponent
                                coordinates={farmPolygonDrawing}
                            />
                        </LoadScript>
                    </div>
                </div>
            </XSidebarWrapper>
            <DeletePurchaseForm id={purchase.id} pagination={pagination} close={close}/>
        </>
    );
};

export default PurchaseDetails;
