import { useDispatch } from "react-redux";
import { Field, Formik, useField } from "formik";
import { useEffect, useState } from "react";
import { useAuth } from "../../auth";
import { useParams } from "react-router-dom";
import { buildUrl } from "../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../data/constants";
import { IBuyer } from "../interfaces/IBuyer";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { post } from "../../../../utils/ajax";
import { BUYERS_CONSTANTS } from "../../../../data/redux/inventory/buyersReducer";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import { BuyerSchema } from "../schemas/BuyerSchema";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { IPaginationMetadata } from "../../../interfaces/IApiResponse";
import {useIntl} from "react-intl";

type Props = {
    hubId?: string
    pagination: IPaginationMetadata
}

const CreateCollectionCentre = ({ hubId, pagination }: Props) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const { currentUser } = useAuth();
    const [district, setDistrict] = useState<string[]>([]);
    const urlParams = useParams()
    hubId = hubId ?? urlParams.hubId;

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        name: '',
        district: '',
        hubId: null,
        email: null,
        eoriNumber: null,
        address: null
    }

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.inventoryService, '/buyers')
        const data: IBuyer = {
            ...values,
            hubId: hubId ?? currentUser?.hubId,
        }

        post(url, data, (response) => {

            CloseModal("new-buyer", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: BUYERS_CONSTANTS.POST_BUYER,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
    }, []);


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={BuyerSchema}
            onSubmit={handleSubmit}>

            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id="new-buyer"
                    title={`${intl.formatMessage({id: "FORM.TITLE.ADDNEWBUYER", defaultMessage: "Add a New Buyer"})}`}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.NAME", defaultMessage: "Name" })}`}
                                name="name"
                            />
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.EMAIL", defaultMessage: "Email" })}`}
                                name="email"
                            />
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({id: "FORM.EORINUMBER", defaultMessage: "EORINumber"})}`}
                                name="eoriNumber"
                            />
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({ id: "FORM.ADDRESS", defaultMessage: "Address" })}`}
                                name="address"
                            />
                        </div>

                    </div>

                </ModalWrapper>
            }}

        </Formik>
    );
}

export default CreateCollectionCentre;