import * as Yup from "yup";

export const InputStockSchema = Yup.object({
    date: Yup.date().required("Record Date is required"),
    inputName: Yup.object().required("Input is required"),
    unitName: Yup.object().required("Unit is required"),
    quantity: Yup.number().required("Quantity is required"),
    unitCost: Yup.number().required("Unit cost is required"),
    totalCost: Yup.number().required("Total cost paid is required"),
    collectionCentreName: Yup.object().required("Required")
})