import * as Yup from "yup";
import { IntlShape } from "react-intl";

export interface IUser {
    additionalClaims?: IClaim[];
    confirmPassword?: string;
    email: string;
  firstName: string;
    id?: string;
  lastName: string;
  password: string;
    phoneNumber: string;
    userName?: string;
}

export interface IClaim {
  key: string;
  value: string;
}

const phoneRegExp = /^(\+?\d{1,4}|\d{1,4})?\s?\d{3}\s?\d{3}\s?\d{3}$/;

export const UserSchema = (intl: IntlShape) =>
  Yup.object({
    firstName: Yup.string()
      .nullable()
      .required(
        `${intl.formatMessage({
          id: "ERROR.FIELD.REQUIRED",
          defaultMessage: "Required",
        })}`,
      ),
    lastName: Yup.string()
      .nullable()
      .required(
        `${intl.formatMessage({
          id: "ERROR.FIELD.REQUIRED",
          defaultMessage: "Required",
        })}`,
      ),
    phoneNumber: Yup.string()
      .nullable()
      .required(
        `${intl.formatMessage({
          id: "ERROR.FIELD.REQUIRED",
          defaultMessage: "Required",
        })}`,
      )
      .matches(
        phoneRegExp,
        `${intl.formatMessage({
          id: "ERROR.TELEPHONE.REQUIRED",
          defaultMessage: "Phone number is not valid.",
        })}`,
      ),
    email: Yup.string()
      .nullable()
      .required(
        `${intl.formatMessage({
          id: "ERROR.FIELD.REQUIRED",
          defaultMessage: "Required",
        })}`,
      )
      .email(
        `${intl.formatMessage({
          id: "ERROR.EMAIL.INVALID",
          defaultMessage: "Invalid email",
        })}`,
      ),
    password: Yup.string()
      .nullable()
      .required(
        `${intl.formatMessage({
          id: "ERROR.FIELD.REQUIRED",
          defaultMessage: "Required",
        })}`,
      ),
    confirmPassword: Yup.string()
      .nullable()
      .required(
        `${intl.formatMessage({
          id: "ERROR.FIELD.REQUIRED",
          defaultMessage: "Required",
        })}`,
      )
      .oneOf(
        [Yup.ref("password")],
        `${intl.formatMessage({
          id: "ERROR.PASSWORD.INVALID",
          defaultMessage: "Passwords must match",
        })}`,
      ),
    additionalClaims: Yup.array()
      .of(
        Yup.object({
          key: Yup.string().required(
            `${intl.formatMessage({
              id: "ERROR.TELEPHONE.REQUIRED",
              defaultMessage: "Phone number is required.",
            })}`,
          ),
          value: Yup.string().required(
            `${intl.formatMessage({
              id: "ERROR.TELEPHONE.REQUIRED",
              defaultMessage: "Phone number is required.",
            })}`,
          ),
        }),
      )
      .max(
        3,
        `${intl.formatMessage({
          id: "ERROR.CLAIMS.NUMBER",
          defaultMessage: "You can add up to 3 claims",
        })}`,
      ),
  });
