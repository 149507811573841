import {Formik, Field} from "formik";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {XTextField} from "../../../components/shared/forms/XTextField";
import {XDatePicker} from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {useAuth} from "../../auth";
import React, {useEffect, useState} from "react";
import {buildUrl} from "../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../data/constants";
import {IInputDistribution} from "../interfaces/IInputDistribution";
import {get, post} from "../../../../utils/ajax";
import {INPUTS_DISTRIBUTION_CONSTANTS} from "../../../../data/redux/inputs/inputsReducer";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages,} from "../../../../data/toastDefaults";
import {InputAdornment} from "@mui/material";
import {ICollectionCentre, IUnit, IInput,} from "../../settings/interfaces/IHub";
import {IApiResponse} from "../../../interfaces/IApiResponse";
import {SETTINGS_CONSTANTS} from "../../../../data/redux/settings/settingsReducer";
import {IOption, XAutoComplete,} from "../../../components/shared/forms/XAutoComplete";
import {InputStockSchema} from "../schemas/InputStockSchema";

const AddStockForm = () => {
    const dispatch = useDispatch();
    const {currentUser} = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());
    const [inputs, setInputs] = useState<IInput[]>([]);
    const [units, setUnits] = useState<IUnit[]>([]);
    const [locations, setLocations] = useState<ICollectionCentre[]>([]);

    const options: IOption[] = units
        .map((u) => ({
            id: u.id ?? "",
            label: u.displayName ?? "",
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const collectionCentres: IOption[] = locations
        .map((c) => ({
            id: c.id ?? "",
            label: c.name ?? "",
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const hubInputs: IOption[] = inputs
        .map((i) => ({
            id: i.id ?? "",
            label: i.name ?? "",
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const initialValues = {
        inputName: "",
        unitName: "",
        quantity: "",
        unitCost: "",
        collectionCentreName: null,
        date: null,
    };

    useEffect(() => {
        handleFetchData();
    }, []);

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            let params: any = {
                pageSize: 100,
            };

            let url = ''
            url = buildUrl(remoteRoutes.onboardingService, "/units", params);
            get(
                url,
                (response: IApiResponse) => {
                    const {data} = response;
                    setUnits(data);
                },
                async (error) => {
                },
                () => {
                    dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                },
            );

            const hubId = currentUser?.hubId

            if (hubId) {
                params = {hubId};

                url = buildUrl(remoteRoutes.onboardingService, "/collection/centres", params);
                get(
                    url,
                    (response: IApiResponse) => {
                        const {data} = response;
                        setLocations(data);
                    },
                    async (error) => {
                    },
                    () => {
                        dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                    },
                )

                url = buildUrl(remoteRoutes.onboardingService, "/inputs", params);
                get(
                    url,
                    (response: IApiResponse) => {
                        const {data} = response;
                        setInputs(data);
                    },
                    async (error) => {
                    },
                    () => {
                        dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                    },
                )
            }

        }
    };

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.inputsService, `/input/purchases`);

        const data: IInputDistribution = {
            hubId: currentUser?.hubId ?? null,
            date: values.date.toISOString(),
            inputName: values.inputName.label,
            inputId: values.inputName.id,
            collectionCentreId: values.collectionCentreName.id,
            collectionCentreName: values.collectionCentreName.label,
            unitName: values.unitName.label,
            unitId: values.unitName.id,
            unitCost: Number(values.unitCost),
            totalCost: Number(values.unitCost) * Number(values.quantity),
            quantity: Number(values.quantity),
        };

        post(
            url,
            data,
            (response) => {
                CloseModal("add-stock", () => {
                    setSubmitting(false);
                    resetForm();
                });
                dispatch({
                    type: INPUTS_DISTRIBUTION_CONSTANTS.POST_DATA,
                    payload: data,
                });
                setModalKey(Date.now());
                toast.success(toastMessages.default.success, overrideToastDefaults);
            },
            (error) => {
                setSubmitting(false);
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => {
            },
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={InputStockSchema}
        >
            {({handleSubmit, isSubmitting, setFieldValue, values}) => {
                return (
                    <ModalWrapper
                        id="add-stock"
                        title="Record Input Stock"
                        size={"lg"}
                        submitting={isSubmitting}
                        key={modalKey}
                        handleSubmit={handleSubmit}
                    >
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XDatePicker}
                                    label="Date"
                                    name="date"
                                    maxDate={dayjs(new Date())}
                                    value={values.date}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={hubInputs || []}
                                    getOptionLabel={(option: any) => (option ? option.label : "")}
                                    values={values.inputName}
                                    label="Input Name"
                                    name="inputName"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Quantity"
                                    name="quantity"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={options || []}
                                    getOptionLabel={(option: any) => (option ? option.label : "")}
                                    values={values.unitName}
                                    label="Inputs Unit"
                                    name="unitName"
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Unit Price"
                                    name="unitCost"
                                    inputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">UGX</InputAdornment>
                                        ),
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const unitCost = Number(e.target.value);
                                        setFieldValue("unitCost", unitCost);
                                        setFieldValue(
                                            "totalCost",
                                            Number(values.quantity) * unitCost,
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Field
                                    type={"number"}
                                    disabled
                                    as={XTextField}
                                    label="Total Amount Paid"
                                    name="totalCost"
                                    inputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">UGX</InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-lg-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    options={collectionCentres || []}
                                    value={values.collectionCentreName}
                                    getOptionLabel={(option: any) => (option ? option.label : "")}
                                    label="Collection Center"
                                    name="collectionCentreName"
                                />
                            </div>
                        </div>
                    </ModalWrapper>
                );
            }}
        </Formik>
    );
};

export default AddStockForm;
