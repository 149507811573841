import React, { useEffect, useState } from 'react'
import TableWrapper from "../../components/shared/TableWrapper";
import { IButtonProps, PageHeader } from "../../components/shared/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import { get } from "../../../utils/ajax";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../data/toastDefaults";
import { useAuth } from "../auth";
import { remoteRoutes } from "../../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { INVENTORY_BATCHES_CONSTANTS } from "../../../data/redux/inventory/batchesReducer";
import { IApiResponse, IPaginationMetadata } from "../../interfaces/IApiResponse";
import { MoveBatchForm } from "./modals/MoveBatchForm";
import { BatchDetails } from "./BatchDetails";
import { IBatch } from "./interfaces/IBatch";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { IMenuItem } from "../../../utils/MainMenu";
import { InventoryInnerMenu } from "./InventoryInnerMenu";
import { useLocation } from "react-router-dom";
import { Modules } from "../../interfaces/Enums";
import { CombineBatchesForm } from "./modals/CombineBatchesForm";
import { DownloadCsvForm } from "./modals/DownloadCsvForm";
import SellBatchSymosForm from "./modals/SellBatchSymosForm";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import XPagination from "../../components/shared/XPagination";
import { IBreadcrumb } from '../../components/shared/Breadcrumbs';
import SelectProductForm from './modals/SelectProductForm';
import { buildUrl } from '../../../utils/queryBuilder';
import {useIntl} from "react-intl";

const columns: ITableColumn[] = [
    {
        localeId: "TABLE.COLUMN.DATEOFBATCHING",
        label: 'Date of Batching',
        id: 'date',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        localeId: "TABLE.COLUMN.BATCHNUMBER",
        label: 'Batch Number',
        id: 'batchNumber',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        localeId: "TABLE.COLUMN.PRODUCT",
        label: 'Product',
        id: 'productName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        localeId: "TABLE.COLUMN.VARIETY",
        label: 'Variety',
        id: 'variety',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        localeId: "TABLE.COLUMN.QUANTITYINKG",
        label: 'Quantity (kg)',
        id: 'quantity',
        link: undefined,
        isNumberFormat: true,
        isDate: false,
        textAlign: 'text-end'
    },
    {
        localeId: "TABLE.COLUMN.CURRENTLOCATION",
        label: 'Current Location',
        id: 'currentCollectionCentreName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        localeId: "TABLE.COLUMN.STATUS",
        label: 'Status',
        id: 'currentStatus',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
]

export const Batches = () => {

    document.title = "Inventory > Batches"

    enum BatchStatus {
        All = 'All',
        Batched = 1,
        InTransit = 2,
        Delivered = 3,
        Processing = 4,
        Processed = 5,
        Sold = 6
    }

    const intl = useIntl();

    const { currentUser } = useAuth()
    const dispatch = useDispatch()

    const [activeSection, setActiveSection] = useState<BatchStatus>(BatchStatus.All);
    const [currentStatus, setCurrentStatus] = useState<number | number[] | null>(null);

    const { data, loading }: any = useSelector((state: IState) => state.batches)
    const [selected, setSelected] = useState<any[]>([])
    const [selectedBatches, setSelectedBatches] = useState<any[]>([])
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    const [selectedBatch, setSelectedBatch] = useState<IBatch | null>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });
    const [showActionButtons, setShowActionButtons] = useState<boolean>(false)
    const location = useLocation()
    const activePage = location.pathname.toLowerCase()
    const [subMenuItems, setSubMenuItems] = useState<IMenuItem[] | undefined>([]);

    const [batchedQuantity, setBatchedQuantity] = useState(0)
    const [productId, setProductId] = useState<string | null | undefined>(undefined);
    const [varietyId, setVarietyId] = useState<string | null | undefined>(undefined);
    const [selectedId, setSelectedId] = useState<string | null | undefined>(undefined);

    const actionButtons: IButtonProps[] = [
        {
            label: `${intl.formatMessage({
                id: selected?.length > 1 ? "BUTTONS.SELLBATCHES" : "BUTTONS.SELLBATCH",
                defaultMessage: selected?.length > 1 ? "Sell batches" : "Sell batch"
            })}`,
            cssClass: "btn btn-sm btn-primary rounded-2 ms-2",
            dataTarget: "#sell-batch-symos",
            dataToggle: "modal"
        },
        {
            label: `${intl.formatMessage({
                id: selected?.length > 1 ? "BUTTONS.MOVEBATCHES" : "BUTTONS.MOVEBATCH",
                defaultMessage: selected?.length > 1 ? "Move batches" : "Move batch"
            })}`,
            cssClass: "btn btn-sm btn-outline-primary rounded-2 ms-2",
            dataTarget: "#move-batch",
            dataToggle: "modal"
        }]

    // if (selected?.length > 0) {
    //     actionButtons.push({
    //         label: `${intl.formatMessage({id: "BUTTONS.DOWNLOADCSV", defaultMessage: "Download CSV"})}`,
    //         cssClass: "btn btn-secondary ms-2",
    //         dataTarget: "#download-csv",
    //         dataToggle: "modal"
    //     })
    // }

    if (selected?.length > 1) {
        actionButtons.push({
            label: `${intl.formatMessage({id: "BUTTONS.COMBINEBATCHES", defaultMessage: "Combine batches"})}`,
            cssClass: "btn btn-sm btn-default rounded-2 ms-2",
            dataTarget: "#combine-batches",
            dataToggle: "modal"
        })
    }

    useEffect(() => {
        const items = InventoryInnerMenu(intl, activePage)
        setSubMenuItems(items)
    }, [activePage])

    const handleCheckRow = (batch: any) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(batch.id);
            if (index > -1) {
                newSelected.splice(index, 1);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity - batch.quantity
                })
            } else {
                newSelected.push(batch.id);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity + batch.quantity
                })
            }

            return newSelected
        })

        setSelectedBatches(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(batch.id);
            if (index > -1) {
                newSelected.splice(index, 1);
            } else {
                newSelected.push(batch);
            }

            return newSelected
        })
    };

    const handleClickRow = (batch: any) => {
        setDrawerVisible(!drawerVisible)
        setSelectedBatch(batch)
    };

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                setBatchedQuantity(0)
                return [];
            } else {
                const totalQuantity = rows.reduce((accumulator: number, item) => accumulator + item.quantity, 0)
                setBatchedQuantity(totalQuantity)
                return rows.map(row => row.id);
            }
        })

        setSelectedBatches(prevSelected => {
            return prevSelected.length === rows.length ? [] : rows;
        })
    };

    useEffect(() => {
        setShowActionButtons(selected && selected.length > 0)
    }, [selected])

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inventory', url: undefined },
            { label: 'Batches', url: undefined }
        ])
    }, [])

    const handleSelectId = (id: string | null, isVariety: boolean) => {
        setSelectedId(id);
        if (isVariety) {
            setVarietyId(id);
            setProductId(undefined);
        } else {
            setProductId(id);
            setVarietyId(undefined);
        }
    };

    const setLoading = (status: boolean) => {
        dispatch({
            type: INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES,
            payload: status
        })
    }

    const handleSectionClick = (section: BatchStatus) => {
        let newStatus: number | number[] | null = null;

        if (section === BatchStatus.All) {
            newStatus = null;
        } else if (section === BatchStatus.Batched) {
            newStatus = [
                BatchStatus.Batched as number,
                BatchStatus.InTransit as number,
                BatchStatus.Delivered as number,
                BatchStatus.Processed as number,
            ];
        } else if (section === BatchStatus.Sold) {
            newStatus = [
                BatchStatus.Sold as number,
                BatchStatus.Processing as number,
            ];
        }

        setActiveSection(section);
        setCurrentStatus(newStatus);
    };

    useEffect(() => {
        fetchBatches(page, pagination.pageSize);
    }, [page, searchTerm, selectedId, currentStatus]);

    const fetchBatches = (page: number, pageSize: number) => {

        dispatch({
            type: INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES,
            payload: true
        });

        let params: any = {
            hubId: currentUser?.hubId,
            page,
            pageSize,
        };

        if (selectedId) {
            if (varietyId) {
                params.varietyId = selectedId;
            } else {
                params.productId = selectedId;
            }
        }

        if (searchTerm) {
            params.searchTerm = searchTerm
        }

        if (currentStatus) {
            params.currentStatus = currentStatus;
        }

        const url = buildUrl(remoteRoutes.inventoryService, '/batches', params);

        get(url,
            (response: IApiResponse) => {
                const { data, paginationMetadata } = response;
                setPagination(paginationMetadata);

                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES,
                    payload: [...data]
                });

                toast.success(toastMessages.default.success, overrideToastDefaults);
            },
            (error) => {
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => {
                dispatch({ type: INVENTORY_BATCHES_CONSTANTS.STOP_FETCH });
                setLoading(false);
            }
        );
    };

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
        setPage(1)
    }

    const formattedDate = Array.isArray(data)
        ? data.map((row: { date: string }) => ({
            ...row,
            date: printNaturalDateShort(new Date(row.date))
        }))
        : [];

    const sectionStyle = (section: any) => ({
        backgroundColor: activeSection === section ? '#f1f1f1' : 'transparent',
        color: activeSection === section ? '#1F5A2D' : 'inherit',
        fontWeight: activeSection === section ? 'bold' : 'normal',
        cursor: 'pointer',
        width: 'auto',
    });

    return (
        <>
            <div className={"py-3"}>
                <InnerMenu module={Modules.Inventory}/>
            </div>

            <div className='bg-white rounded-3 shadow-lg mb-3 row'
                 style={{width: '100%', height: 35, margin: '0', border: '1px solid #D9D9D9', padding: '0'}}>
                <div
                    className="d-flex bg-grey align-items-center justify-content-center h-100"
                    style={{marginLeft: 20, ...sectionStyle('All')}}
                    onClick={() => handleSectionClick(BatchStatus.All)}>
                    All
                </div>
                <div
                    className="d-flex bg-grey align-items-center justify-content-center h-100 px-2"
                    style={sectionStyle(BatchStatus.Batched)}
                    onClick={() => handleSectionClick(BatchStatus.Batched)}
                >
                    Available
                </div>
                {/*<div*/}
                {/*    className="d-flex bg-grey align-items-center justify-content-center h-100 px-2"*/}
                {/*    style={sectionStyle(BatchStatus.Sold)}*/}
                {/*    onClick={() => handleSectionClick(BatchStatus.Sold)}*/}
                {/*>*/}
                {/*    Unavailable*/}
                {/*</div>*/}
            </div>

                <TableWrapper>
                    <div className="d-flex w-100 align-items-center justify-content-between">
                        <div className="input-group w-25">
                            <input type="text" onKeyUp={(event) => handleSearch(event)}
                                   className="form-control"
                                   placeholder={intl.formatMessage({
                                       id: "TABLE.SEARCH",
                                       defaultMessage: "Type to search...",
                                   })}/>
                            <span className="input-group-text" id="addon-wrapping">
                                <FontAwesomeIcon icon={faSearch}/>
                            </span>
                        </div>

                    <div className="action-buttons">
                        {showActionButtons && activeSection === BatchStatus.Batched &&
                            actionButtons.map((button, index) => {
                                return (<button
                                    data-bs-toggle={button.dataToggle}
                                    data-bs-target={button.dataTarget}
                                    className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={button.onClick}>
                                    {button.processing ? 'Please wait...' : button.label}
                                </button>)
                            })
                        }

                        {activeSection === BatchStatus.Batched && <>
                            <button type="button"
                                    data-bs-toggle={"modal"}
                                    data-bs-target={"#select-product"}
                                    className="btn btn-sm btn-default rounded-2 ms-2">
                                <span className="ms-2">Combine batches</span>
                            </button>
                        </>}

                        {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
                        {/*    <FontAwesomeIcon icon={faFilter} className="text-orange"/>*/}
                        {/*    <span className="ms-2">Filters</span>*/}
                        {/*</button>*/}
                        {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
                        {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
                        {/*</button>*/}
                    </div>
                </div>

                <XTable
                    selected={selected}
                    onSelectAll={(rows) => handleSelectAll(rows)}
                    onRowClick={(row) => handleClickRow(row)}
                    onCheckRow={(row) => {
                        if (activeSection === BatchStatus.Batched && selectedId !== undefined) {
                            handleCheckRow(row);
                        }
                    }}
                    data={formattedDate}
                    columns={columns}
                    loading={loading}
                    checked={selectedId !== undefined && activeSection === BatchStatus.Batched}
                />

                <XPagination
                    currentPage={page}
                    pagination={pagination}
                    dataLength={data.length}
                    setPage={(page) => setPage(page)}/>

            </TableWrapper>

            <SelectProductForm onSelectId={handleSelectId}/>
            <CombineBatchesForm batches={selectedBatches}/>
            <MoveBatchForm batch={selectedBatch}/>
            <DownloadCsvForm batches={selected}/>
            <SellBatchSymosForm batches={selected}/>
            <BatchDetails close={() => setDrawerVisible(false)} show={drawerVisible} batchId={selectedBatch?.id}/>
        </>
    )
}