import {IFarmerProfile} from "../../../app/modules/profiling/interfaces/IFarmerProfile";
import {IBatch} from "../../../app/modules/inventory/interfaces/IBatch";
import {IPaginationMetadata} from "../../../app/interfaces/IApiResponse";

export const INVENTORY_BATCHES_CONSTANTS = {
    DELETE_BATCH: "DELETE_BATCH",
    FETCH_BATCHES: "FETCH_BATCHES",
    FILTER_DATA: "FILTER_DATA",
    GET_BATCH: "GET_BATCH",
    LOADING_BATCHES: "LOADING_BATCHES",
    PAGINATION: "PAGINATION",
    POST_BATCH: "POST_BATCH",
    RESET_FILTER: "RESET_FILTER",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    STOP_FETCH: "STOP_FETCH",
    UPDATE_BATCH: "UPDATE_BATCH",
}

export interface IBatchState {
    loading: boolean
    data: IBatch[]
    pagination: IPaginationMetadata
    filter: {
        show: boolean,
        data: IBatch[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IBatchState = {
    loading: false,
    data: [],
    pagination: {
        currentPage: 1,
        pageSize: 15,
        hasPreviousPage: false,
        hasNextPage: false,
        totalItems: 0,
        totalPages: 0
    },
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function batchesReducer(state = initialState, action: any) {
    switch (action.type) {

        case INVENTORY_BATCHES_CONSTANTS.FILTER_DATA:
            return {...state, ...action.payload, show: !state.filter.show}

        case INVENTORY_BATCHES_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return {...initialState, reset: true}

        case INVENTORY_BATCHES_CONSTANTS.SET_SORT_PARAMS:
            return {...state, ...action.payload}

        case INVENTORY_BATCHES_CONSTANTS.PAGINATION:
            return {...state, ...action.payload}

        case INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES: {
            return {...state, loading: false, data: action.payload, reset: false}
        }

        case INVENTORY_BATCHES_CONSTANTS.POST_BATCH: {
            return {...state, data: [action.payload, ...state.data]}
        }

        case INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH: {
            return {
                ...state,
                data: state.data.map(batch => batch.id === action.payload.id ? action.payload : batch)
            }
        }

        case INVENTORY_BATCHES_CONSTANTS.DELETE_BATCH: {
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                data: state.data.filter(batch => batch.id !== action.payload.id),
                pagination
            }
        }

        case INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES: {
            return {...state, loading: action.payload}
        }

        case INVENTORY_BATCHES_CONSTANTS.GET_BATCH: {
            return {...state, requestDetails: action.payload}
        }

        case INVENTORY_BATCHES_CONSTANTS.STOP_FETCH: {
            return {...state, loading: false}
        }

        default: {
            return state
        }
    }
}
