import * as Yup from "yup";
import {IntlShape} from "react-intl";

export interface IInput {
    id: string
    hubId: string
    name: string
    category: string
    units?: IdName [] | []
    description?: string
}

export interface IHubInput {
    id?: string
    hubId?: string
    name: string
    description?: string
    category: string
    categoryName?: string
    otherInputCategory?: string,
    units?: IdName [] | []
}

export interface IInputDto {
    id?: string
    hubId?: string
    name: string
    description?: string
    category: number
    otherInputCategory?: string,
    units?: IdName []
}

export interface IdName {
    id: string
    name: string
}

export const InputSchema = (intl: IntlShape) => Yup.object({
    name: Yup.string().nullable().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`),
    // category: Yup.object().shape({
    //     id: Yup.string().required('Required'),
    //     label: Yup.number().required('Required'),
    // }).nullable().required('Required'),
    hubId: Yup.string().required(`${intl.formatMessage({
        id: "ERROR.FIELD.REQUIRED",
        defaultMessage: "Required"
    })}`)
})