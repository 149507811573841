import {IHeaderButtonProps} from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import React, {useCallback, useEffect, useState} from "react";
import {ITableColumn, XTable} from "../../../components/shared/XTable";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../auth";
import {
  IApiResponse,
} from "../../../interfaces/IApiResponse";
import {IState} from "../../../../data/types";
import {
  ISettingsState,
  SETTINGS_CONSTANTS,
} from "../../../../data/redux/settings/settingsReducer";
import {remoteRoutes} from "../../../../data/constants";
import {buildUrl} from "../../../../utils/queryBuilder";
import {get} from "../../../../utils/ajax";
import {toast} from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import {ISystemProduct} from "../../settings/products/interfaces/ISystemProduct";
import CreateNewUserForm from "./modals/CreateNewUserForm";
import {IUser} from "./interfaces/IUser";
import UserDetails from "./UserDetailsPage";
import {PageHeader} from "../../../components/shared/PageHeader";
import {IBreadcrumb} from "../../../components/shared/Breadcrumbs";
import debounce from "lodash.debounce";
import {FormattedMessage, useIntl} from "react-intl";

const getRoleValue = (
    additionalClaims?: { key: string; value: string }[],
): string => {
  if (!additionalClaims) return "";
  const roleClaim = additionalClaims.find((claim) => claim.key === "role");
  return roleClaim ? roleClaim.value : "No Role Assigned";
};

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.FIRSTNAME",
    label: "First Name",
    id: "firstName",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.LASTNAME",
    label: "Last Name",
    id: "lastName",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
    {
        localeId: "TABLE.COLUMN.ROLE",
        label: "Role",
        id: "role",
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: "text-start",
    },
  {
    localeId: "TABLE.COLUMN.TELEPHONE",
    label: "Phone Number",
    id: "phoneNumber",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.EMAIL",
    label: "Email",
    id: "email",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  }
];

interface IProps {
  title?: string;
  hubId?: string;
  includeMyHubUsers?: boolean;
}

export const Users = ({title, hubId, includeMyHubUsers}: IProps) => {
  document.title = "Symos > Users";
    const intl = useIntl();
  const dispatch = useDispatch();
  const urlParams = useParams();
  const {currentUser} = useAuth();

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    additionalClaims: [],
  };

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);

  const {users, loading}: ISettingsState = useSelector(
      (state: IState) => state.settings,
  );
  const {pagination, data} = users;

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [selectedUser, setSelectedUser] = useState<IUser>(initialValues);

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const debouncedSearch = useCallback(
      debounce((value) => {
        setSearchTerm(value);
        setPage(1);
      }, 800),
      [],
  );

  const setLoading = (state: boolean) => {
    dispatch({
      type: SETTINGS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const filterUsers = () => {
    if (searchTerm) {
      setFilteredUsers(
          (data as unknown as IUser[]).filter((user) =>
              user.firstName.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
      );
    } else {
      setFilteredUsers(data as unknown as IUser[]);
    }
  };

  useEffect(() => {
  }, []);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
    filterUsers();
  }, [page]);

    useEffect(() => {
        filterUsers()
    }, [searchTerm, users]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFetchData = (
      page: number,
      pageSize: number,
      searchTerm?: string,
  ) => {
    if (remoteRoutes.authServer) {
      const myHubId = currentUser?.hubId;

      const url = buildUrl(
          remoteRoutes.onboardingService,
          `/hubs/${hubId ?? myHubId}/child-ids`,
      );
      get(
          url,
          (response: any) => {
            if (hubId) response.push(hubId);
            if (includeMyHubUsers) response.push(myHubId);

            let params: any = {page, pageSize, hubIds: response?.join(",")};

            if (searchTerm) {
              params.searchTerm = searchTerm;
            }

            const url = buildUrl(
                remoteRoutes.authServer,
                `/api/v1/users`,
                params,
            );
            get(
                url,
                (response: IApiResponse) => {
                  const {data, paginationMetadata} = response;

                  dispatch({
                    type: SETTINGS_CONSTANTS.FETCH_USERS,
                    payload: {data, pagination: paginationMetadata},
                  });

                  toast.success(
                      toastMessages.default.success,
                      overrideToastDefaults,
                  );
                },
                async (error) => {
                  toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                  dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                  setLoading(false);
                },
            );
          },
          async (error) => {
            toast.error(toastMessages.default.fail, overrideToastDefaults);
          },
          () => {
          },
      );
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: ISystemProduct) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: IUser) => {
    setDrawerVisible(!drawerVisible);
    setSelectedUser(item);
  };

  const actionButtons: IHeaderButtonProps[] = [
    {
      label: `${intl.formatMessage({id: "BUTTONS.ADDNEWUSER", defaultMessage: "Add a New User"})}`,
      cssClass: "btn btn-primary btn-sm",
      dataTarget: "#new-user",
      dataToggle: "modal",
    },
  ];

  const updatedRowData = filteredUsers.map((row) => ({
    ...row,
    role: getRoleValue(row.additionalClaims), // Safely get the role value
  }));

  useEffect(() => {
    setBreadcrumbs([{label: "Users", url: undefined}]);
  }, []);

  return (
      <>
        {title && <PageHeader title={title} breadcrumbs={breadcrumbs}/>}

        <TableWrapper>
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="input-group w-25">
              <input
                  type="text"
                  // onKeyUp={(event) => handleSearch(event)}
                  onChange={handleSearchChange}
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: "TABLE.SEARCH",
                    defaultMessage: "Type to search...",
                  })}
              />
              <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch}/>
            </span>
            </div>

            <div className="action-buttons">
              {/*<button*/}
              {/*  type="button"*/}
              {/*  className="btn btn-sm btn-outline-secondary ms-2"*/}
              {/*>*/}
              {/*  <FontAwesomeIcon icon={faFilter} className="text-orange" />*/}
              {/*  <span className="ms-2">*/}
              {/*    <FormattedMessage*/}
              {/*      id={"BUTTONS.FILTERS"}*/}
              {/*      defaultMessage={"Filters"}*/}
              {/*    />*/}
              {/*  </span>*/}
              {/*</button>*/}
              {/*<button*/}
              {/*  type="button"*/}
              {/*  className="btn btn-sm btn-outline-secondary ms-2 me-2"*/}
              {/*>*/}
              {/*  <FontAwesomeIcon*/}
              {/*    icon={faEllipsisVertical}*/}
              {/*    className="text-orange"*/}
              {/*  />*/}
              {/*</button>*/}

              {showActionButtons &&
                  actionButtons.map((button, index) => {
                    return (
                        <button
                            data-bs-toggle={button.dataToggle}
                            data-bs-target={button.dataTarget}
                            className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                            key={index}
                            onClick={button.onClick}
                        >
                          {button.processing
                              ? `${intl.formatMessage({
                                id: "LOADERS.PLEASEWAIT",
                                defaultMessage: "Please wait...",
                              })}`
                              : button.label}
                        </button>
                    );
                  })}
            </div>
          </div>

          <XTable
              selected={selected}
              onSelectAll={(rows) => handleSelectAll(rows)}
              onRowClick={(row) => handleClickRow(row)}
              onCheckRow={(row) => handleCheckRow(row)}
              data={updatedRowData}
              columns={columns}
              loading={loading}
          />

          <XPagination
              dataLength={users?.data?.length}
              pagination={pagination}
              currentPage={page}
              setPage={(page) => setPage(page)}
          />
        </TableWrapper>

        <CreateNewUserForm
            hubName={currentUser?.hubName}
            hubId={urlParams.hubId ?? currentUser?.hubId}
            pagination={pagination}
        />
        <UserDetails
            close={() => setDrawerVisible(false)}
            show={drawerVisible}
            user={selectedUser}
            pagination={pagination}
        />
      </>
  );
};
