import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { faFilter, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { PageHeader } from "../../components/shared/PageHeader";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import XPagination from "../../components/shared/XPagination";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../interfaces/IApiResponse";
import { remoteRoutes } from "../../../data/constants";
import { buildUrl } from "../../../utils/queryBuilder";
import { get } from "../../../utils/ajax";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../data/toastDefaults";
import { useAuth } from "../auth";
import {
  IInputsDistributionState,
  INPUTS_DISTRIBUTION_CONSTANTS,
} from "../../../data/redux/inputs/inputsReducer";
import { IState } from "../../../data/types";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import InputSaleDetails from "./InputSaleDetails";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";
import { authService } from "../../../data/oidc/AuthService";
import { IHub } from "../settings/interfaces/IHub";
import InputSalesFilterForm from "./modals/InputSalesFilterForm";
import debounce from "lodash.debounce";

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.DATEOFSALE",
    label: "Date of Sale",
    isDate: false,
    isNumberFormat: false,
    id: "formattedDate",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.FARMERNAME",
    label: "Farmer Name",
    id: "farmerName",
    textAlign: "text-start"
  },
  {
    localeId: "TABLE.COLUMN.INPUTNAME",
    label: "Input Name",
    id: "inputName",
    textAlign: "text-start"
  },
  {
    localeId: "TABLE.COLUMN.QUANTITY",
    label: "Quantity",
    isNumberFormat: true,
    id: "quantity",
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.UNIT",
    label: "",
    isNumberFormat: false,
    id: "unitName",
    textAlign: "text-start"
  },
  {
    localeId: "TABLE.COLUMN.TOTAL",
    label: "Total Price",
    isNumberFormat: true,
    id: "totalPrice",
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.AMOUNTPAID",
    label: "Amount Paid",
    isNumberFormat: true,
    id: "amountPaid",
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.BALANCE",
    label: "Balance",
    isNumberFormat: true,
    id: "balanceDue",
    textAlign: "text-end",
  },
];

interface Props {
  showHeader?: boolean;
}

export const InputSales = ({ showHeader = true }: Props) => {
  document.title = "Inputs > Sales";

  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = useParams();
  const { currentUser } = useAuth();

  const [selectedRow, setSelectedRow] = useState<IInputDistribution | null>(
    null,
  );

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 15,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: 0,
    totalPages: 0,
  });

  const { data, loading }: IInputsDistributionState = useSelector(
    (state: IState) => state.inputs,
  );

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
    setPage(1);
  };

  const handleApplyFilters = (FilterValues: any) => {
    setFilters(FilterValues);
    setPage(1);
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [page, searchTerm, filters]);

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    const hub: IHub | undefined = authService.getHub();

    const hubId = currentUser?.hubId;
    const farmerId = urlParams.farmerId;

    const parentHubId = hub?.parentHubId;

    const CollectionCentreIds = hub?.collectionCentres?.map(
      (centre) => centre.id,
    );

    let params: any;

    if (location.pathname.includes("/farmers")) {
      params = { farmerId, page, pageSize, ...filters };
    } else if (parentHubId === null) {
      params = { hubId, page, pageSize, ...filters };
    } else {
      params = {
        hubId,
        parentHubId,
        CollectionCentreIds,
        page,
        pageSize,
        ...filters,
      };
    }

    if (searchTerm) {
      params.searchTerm = searchTerm;
    }

    const url = buildUrl(remoteRoutes.inputsService, "/input/sales", params);

    get(
      url,
      (response: IApiResponse) => {
        const { data, paginationMetadata } = response;
        setPagination(paginationMetadata);

        dispatch({
          type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
          payload: data,
        });

        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {
        dispatch({ type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH });
        setLoading(false);
      },
    );
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchTerm(value);
      setPage(1);
      handleFetchData(page, pagination.pageSize, value);
    }, 800),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleRowClick = (item: any) => {
    setDrawerVisible(true);
    setSelectedRow(item);
  };

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
  };

  const formattedData = data.map((row: any) => ({
    ...row,
    formattedDate: printNaturalDateShort(row.date),
  }));

  useEffect(() => {
    setBreadcrumbs([
      { label: "Inputs", url: undefined },
      { label: "Sales", url: undefined },
    ]);
  }, []);

  return (
    <>
      {showHeader && loading && <PageHeaderLoader />}
      {showHeader && !loading && (
        <>
          <PageHeader breadcrumbs={breadcrumbs}>
            <InnerMenu module={Modules.Inputs} />
          </PageHeader>
        </>
      )}

      <div className={"row"}>
        <div className={filterVisible ? "col-9" : "col-12"}>
          <TableWrapper>
            <div className="d-flex w-100 align-items-center justify-content-between">
              <div className="input-group w-25">
                <input
                  type="text"
                  ref={searchInputRef}
                  // onKeyUp={(event) => handleSearch(event)}
                  onChange={handleSearchChange}
                  className="form-control"
                  placeholder="Type to search..."
                />
                <span className="input-group-text" id="addon-wrapping">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>

              <div className={"d-flex"}>
                <div className="action-buttons">
                  <button
                    onClick={handleFilterClick}
                    type="button"
                    className="btn btn-sm btn-outline-secondary ms-2"
                  >
                    <FontAwesomeIcon icon={faFilter} className="text-orange" />
                    <span className={"ms-2"}>Filters</span>
                  </button>
                </div>
              </div>
            </div>
            <XTable
              checked={false}
              selected={[]}
              onRowClick={(item) => handleRowClick(item)}
              data={formattedData}
              columns={columns}
              loading={loading}
              onCheckRow={handleRowClick}
            />
            <XPagination
              currentPage={page}
              pagination={pagination}
              dataLength={data.length}
              setPage={setPage}
            />
          </TableWrapper>
        </div>

        {filterVisible && (
          <div className={"col-3"}>
            <TableWrapper>
              <div className="border-bottom mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                <div style={{ fontSize: 15, fontWeight: 400 }}>Filter</div>
                <div style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={handleFilterClick}
                    size="lg"
                  />
                </div>
              </div>
              <InputSalesFilterForm
                onApplyFilters={handleApplyFilters}
                setFilters={setFilters}
              />
            </TableWrapper>
          </div>
        )}
      </div>

      <InputSaleDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        selectedRow={selectedRow}
      />
    </>
  );
};
