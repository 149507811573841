import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {Field, Formik} from "formik";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {get, post} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {IdName, IInputDto, InputSchema} from "../interfaces/IInput";
import {useAuth} from "../../../auth";
import {useDispatch, useSelector} from "react-redux";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {IHub, IInputCategory, IUnit} from "../../interfaces/IHub";
import {IApiResponse, IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {Autocomplete, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {authService} from "../../../../../data/oidc/AuthService";
import {useIntl} from "react-intl";

type Props = {
    hubId?: string
    pagination: IPaginationMetadata
}

const CreateNewInputForm = ({hubId, pagination}: Props) => {
    const intl = useIntl();
    const auth = useAuth();
    const currentUser = auth?.currentUser;
    const dispatch = useDispatch();

    const [units, setUnits] = useState<IUnit[]>([]);
    const options = units.map(u => ({
        id: u.id ?? '', name: u.name ?? ''
    }));

    const inputCategories = auth.categories?.inputCategories;
    const categories = inputCategories?.map((category: IInputCategory) => ({
        label: category.name,
        id: category.number
    }));

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        hubId: hubId,
        name: "",
        category: null,
        description: '',
        otherInputCategory: "",
        units: [] as IdName[]
    };

    useEffect(() => {
        handleFetchData();
    }, []);

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            const url = buildUrl(remoteRoutes.onboardingService, '/units');
            get(url,
                (response: IApiResponse) => {
                    const {data} = response;
                    setUnits(data);
                },
                async (error) => {
                    // toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                });
        }
    };

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.onboardingService, '/inputs');
        const data: IInputDto = {
            name: values.name,
            hubId: hubId ?? currentUser?.hubId,
            category: values.category.id,
            description: values.description,
            units: values.units,
            otherInputCategory: values.otherInputCategory
        };

        post(url, data, (response) => {

            let hub: IHub = authService.getHub()
            hub.inputs?.push({
                id: response.id,
                name: values.name,
                description: values.description,
                category: values.category.id,
                categoryName: values.category.name,
                units: values.units,
            })

            authService.updateHub(hub)

            CloseModal("create-new-input", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_INPUTS,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            });

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };

    const validationSchema = InputSchema(intl)

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {({handleSubmit, isSubmitting, touched, errors, values, setFieldValue}) => (
                    <ModalWrapper
                        id="create-new-input"
                        title={`${intl.formatMessage({id: "FORM.TITLE.NEWiNPUT", defaultMessage: "New input"})}`}
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({id: "FORM.NAME", defaultMessage: "Name"})}`}
                                    name="name"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    label={`${intl.formatMessage({id: "FORM.CATEGORY", defaultMessage: "Category"})}`}
                                    options={categories}
                                    name="category"
                                    onChange={(event: any, value: {
                                        label: string,
                                        id: number
                                    }) => setFieldValue('category', value)}
                                    value={values.category}
                                />
                            </div>

                            <div className="col-lg-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.DESCRIPTION",
                                        defaultMessage: "Description"
                                    })}`}
                                    name="description"
                                />
                            </div>

                            {values.category && (values.category as any).label === 'Others' && (
                                <div className="col-lg-12">
                                    <Field
                                        type={"string"}
                                        disabled={isSubmitting}
                                        as={XTextField}
                                        abel={`${intl.formatMessage({
                                            id: "FORM.OTHERINPUTCATEGORY",
                                            defaultMessage: "Other Input Category"
                                        })}`}
                                        name="otherInputCategory"
                                    />
                                </div>
                            )}

                            <div className="col-lg-12">
                                <Field name="units">
                                    {() => (
                                        <Autocomplete
                                            multiple
                                            getOptionLabel={(option: IdName) => option.name}
                                            options={options}
                                            onChange={(event, value) => {
                                                setFieldValue('units', value.map(unit => ({
                                                    id: unit.id, name: unit.name
                                                })));
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${intl.formatMessage({
                                                        id: "FORM.SELECTUNITS",
                                                        defaultMessage: "Select units"
                                                    })}`}
                                                    variant="standard"
                                                    error={touched.units && Boolean(errors.units)}
                                                    helperText={touched.units && typeof errors.units === 'string' ? errors.units : ''}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>
                    </ModalWrapper>
                )}
            </Formik>
        </>
    );
};

export default CreateNewInputForm;
