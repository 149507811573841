import React, { useCallback, useEffect, useRef, useState } from "react";
import { IButtonProps, PageHeader } from "../../components/shared/PageHeader";
import { ITableColumn, XTable } from "../../components/shared/XTable";
import {
  faDownload,
  faFilter,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { remoteRoutes } from "../../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { FARMER_PROFILES_CONSTANTS } from "../../../data/redux/farmers/profilesReducer";
import { buildUrl } from "../../../utils/queryBuilder";
import { useAuth } from "../auth";
import toast from "react-hot-toast";
import { overrideToastDefaults } from "../../../data/toastDefaults";
import {get, getToken, triggerDownLoad} from "../../../utils/ajax";
import TableWrapper from "../../components/shared/TableWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFarmerProfile } from "./interfaces/IFarmerProfile";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../interfaces/IApiResponse";
import XPagination from "../../components/shared/XPagination";
import Plots from "./partials/_plots";
import { Modules } from "../../interfaces/Enums";
import { InnerMenu } from "../../components/shared/InnerMenu";
import {useNavigate, useSearchParams} from "react-router-dom";
import FarmerProfilesFilterForm from "./modals/FarmerProfilesFilterForm";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";
import debounce from "lodash.debounce";
import * as superagent from 'superagent'
import {authService} from "../../../data/oidc/AuthService";
import {IsHubAdmin} from "../../hooks/roleChecker";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  showHeader?: boolean;
  hubId?: string;
};

const FarmerProfiles = ({ showHeader = true, hubId }: Props) => {
  document.title = "Farmers > Profiles";
    const intl = useIntl();
  const [searchParams] = useSearchParams();

  const roles = authService.getRoles();
  const isHubAdmin = IsHubAdmin(roles)

  const currentPage = searchParams.get("page") || 1;

  const navigate = useNavigate();
  const [page, setPage] = useState<number>(Number(currentPage));
  const [loadMore, setLoadMore] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState(undefined);

  const { currentUser } = useAuth();

  const dispatch = useDispatch();
  const { data, loading }: any = useSelector((state: IState) => state.farmers);

  const [filterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);

  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 25,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: 0,
    totalPages: 0,
  });

  const [importing, setImporting] = useState(false);
  const [childhubArray, setChildhubArray] = useState<any[] | []>([]);

  const setLoading = (status: boolean) => {
    dispatch({
      type: FARMER_PROFILES_CONSTANTS.LOADING_PROFILES,
      payload: status,
    });
  };

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [searchTerm, page, filters]);

  const debouncedSearch = useCallback(
      debounce((value) => {
        setSearchTerm(value);
        setPage(1);
      }, 800),
      [],
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
    setPage(1);
  };

  const hub = hubId ?? currentUser?.hubId;

  const handleFetchData = (
      page: number,
      pageSize: number,
      searchTerm?: string,
  ) => {
    if (remoteRoutes.profiles) {

      let params: any = { hubId: hub, page, pageSize, ...filters };

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(remoteRoutes.profiles, "/farmers", params);
      get(
          url,
          (response: IApiResponse) => {
            const {data, paginationMetadata} = response;

            setPagination(paginationMetadata);

            dispatch({
              type: FARMER_PROFILES_CONSTANTS.FETCH_PROFILES,
              payload: data,
            });

            dispatch({
              type: FARMER_PROFILES_CONSTANTS.PAGINATION,
              payload: paginationMetadata,
            });
          },
          () =>
              toast.error("Error while fetching profiles", overrideToastDefaults),
          () => {
            dispatch({type: FARMER_PROFILES_CONSTANTS.STOP_FETCH});
            setLoading(false);
          },
      );
    }
  };

  const actionButtons: IButtonProps[] = [
    {
      label: "Profile a farmer",
      cssClass: "btn btn-primary",
      processing: importing,
    },
  ];

  const columns: ITableColumn[] = [
      {
          localeId: "TABLE.COLUMN.FULLNAME",
          label: "Name",
          id: "fullname",
      },
      {
          localeId: "TABLE.COLUMN.TELEPHONE",
          label: "Telephone",
          id: "primaryTelephone",
      },
      {
          localeId: "TABLE.COLUMN.DISTRICT",
          label: "District",
          id: "district",
      },
      {
          localeId: "TABLE.COLUMN.SUBCOUNTY",
          label: "Subcounty",
          id: "subCounty",
      },
      {
          localeId: "TABLE.COLUMN.PARISH",
          label: "Parish",
          id: "parish",
      },
      {
          localeId: "TABLE.COLUMN.VILLAGE",
          label: "Village",
          id: "village",
      },
      {
          localeId: "TABLE.COLUMN.MAU",
          label: "MAU",
          id: "hubName",
      },
    // {label: "Created By", id: "createdBy"},
      {
          localeId: "TABLE.COLUMN.AGENTNAME",
          label: "Agent Name",
          id: "agentName",
      },
  ];

  const handleRowClick = (row: IFarmerProfile) => {
    navigate(`/farmers/${row.id}`);
  };

  const [showMap, setShowMap] = useState(false);

  const farmers: IFarmerProfile[] = data;

  const plots: any[] = farmers.flatMap(
      (farmer: IFarmerProfile) => farmer.plots,
  );

  const handleApplyFilters = (FilterValues: any) => {
    setFilters(FilterValues);
    setPage(1);
  };

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
  };

  const getChildHubs = () => {
    const url = buildUrl(remoteRoutes.onboardingService, `/hubs/${hub}/child-ids`);

    get(url, (response: IApiResponse) => {
      if (Array.isArray(response)) {
        const updatedHubArray = [hub, ...response];
        setChildhubArray(updatedHubArray);
      } else {
        console.warn("Unexpected API response format:", response);
      }
    });
  };

  useEffect(() => {
    getChildHubs()
  }, []);

  useEffect(() => {
  }, [childhubArray]);

  const exportFarmerList = async () => {

    try {
      const data = {
        hubIds: childhubArray,
        includeFarmerDetails: true,
        includePolygons: true,
        includeCompliance: true,
      };

      const url = buildUrl(remoteRoutes.profiles, `/farmers/export`);

      const response = await superagent
          .post(url)
          .set('Authorization', `Bearer ${getToken()}`)
          .set('Accept', 'application/json')
          .send(data)
          .responseType('blob');

      const contentDisposition = response.header['content-disposition'];

      let filename = `FarmersExport.xlsx`;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename\*?=.*?(["']?)([^;\n]*?)\1(?:;|$)/i);
        if (filenameMatch) {
          filename = decodeURIComponent(filenameMatch[2].replace(/['"]/g, ''));
        }
      }

      const blob = new Blob([response.body], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      triggerDownLoad(blob, filename)
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  useEffect(() => {
    setBreadcrumbs([
      { label: "Farmers", url: undefined },
      { label: "Profiles", url: undefined },
    ]);
  }, []);

  return (
      <>
        {showHeader && (
            <PageHeader actionButtons={actionButtons} breadcrumbs={breadcrumbs}>
              <InnerMenu module={Modules.Profiles}/>
            </PageHeader>
        )}

      {!showMap && (
        <div className="row">
          <div className={filterVisible ? "col-9" : "col-12"}>
            <TableWrapper>
              <div className="d-flex w-100 align-items-center justify-content-between">
                <div className="input-group w-25">
                  <input
                    type="text"
                    // onKeyUp={(event) => handleSearch(event)}
                    onChange={handleSearchChange}
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "TABLE.SEARCH",
                      defaultMessage: "Type to search...",
                    })}
                  />
                  <span className="input-group-text" id="addon-wrapping">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </div>

                    <div className="action-buttons">
                      <button
                          onClick={handleFilterClick}
                          type="button"
                          className="btn btn-sm btn-outline-secondary ms-2"
                      >
                        <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                        <span className="ms-2">
                            <FormattedMessage
                                id="BUTTONS.FILTERS"
                                defaultMessage="Filters"
                            />
                        </span>
                      </button>
                      {isHubAdmin && data.length !== 0 && <>
                        <button
                            onClick={exportFarmerList}
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                        >
                          <FontAwesomeIcon icon={faDownload} className="text-white"/>
                          <span className="ms-2">Download File</span>
                        </button>
                      </>}
                      {/*<button type="button" className="btn btn-sm btn-outline-secondary ms-2">*/}
                      {/*    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>*/}
                      {/*</button>*/}
                    </div>
                  </div>
                  <XTable
                      checked={false}
                      onRowClick={(row) => handleRowClick(row)}
                      loadingMore={loadMore}
                      loading={loading}
                      columns={columns}
                      data={data}
                  />

                  <XPagination
                      dataLength={data.length}
                      pagination={pagination}
                      currentPage={page}
                      setPage={(page) => setPage(page)}
                  />
                </TableWrapper>
              </div>
              {filterVisible && (
                  <div className="col-3">
                    <TableWrapper>
                      <div
                          className="border-bottom mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                        <div style={{fontSize: 15, fontWeight: 400}}>Filter</div>
                        <div style={{cursor: "pointer"}}>
                          <FontAwesomeIcon
                              icon={faXmark}
                              onClick={handleFilterClick}
                              size="lg"
                          />
                        </div>
                      </div>
                      <FarmerProfilesFilterForm
                          onApplyFilters={handleApplyFilters}
                          setFilters={setFilters}
                      />
                    </TableWrapper>
                  </div>
              )}
            </div>
        )}

        {showMap && (
            <div className="row">
              <div className="col-12">
                <Plots plots={plots}/>
              </div>
            </div>
        )}
      </>
  );
};

export { FarmerProfiles };
