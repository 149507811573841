import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faFilter, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IButtonProps, PageHeader } from "../../components/shared/PageHeader";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import XPagination from "../../components/shared/XPagination";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../interfaces/IApiResponse";
import {
  IInputsDistributionState,
  INPUTS_DISTRIBUTION_CONSTANTS,
} from "../../../data/redux/inputs/inputsReducer";
import { IState } from "../../../data/types";
import { IInputDistribution } from "./interfaces/IInputDistribution";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddStockForm from "./modals/AddStockForm";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import { remoteRoutes } from "../../../data/constants";
import { buildUrl } from "../../../utils/queryBuilder";
import { get } from "../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../data/toastDefaults";
import { FARMER_PROFILES_CONSTANTS } from "../../../data/redux/farmers/profilesReducer";
import { useAuth } from "../auth";
import InputPurchaseDetails from "./InputPurchaseDetails";
import { IBreadcrumb } from "../../components/shared/Breadcrumbs";
import { authService } from "../../../data/oidc/AuthService";
import { IHub } from "../settings/interfaces/IHub";
import InputPurchasesFilterForm from "./modals/InputPurchasesFilterForm";
import debounce from "lodash.debounce";

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.STOCKINGDATE",
    label: "Stocking Date",
    isDate: false,
    id: "formattedDate",
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.INPUTNAME",
    label: "Input Name",
    id: "inputName",
    textAlign: "text-start"
  },
  {
    localeId: "TABLE.COLUMN.QUANTITY",
    label: "Quantity",
    isNumberFormat: true,
    id: "quantity",
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.UNIT",
    label: "",
    id: "unitName",
    textAlign: "text-start"
  },
  {
    localeId: "TABLE.COLUMN.UNITPRICE",
    label: "Unit Price (UGX)",
    isNumberFormat: true,
    id: "unitCost",
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.AMOUNTPAID",
    label: "Total Amount Paid (UGX)",
    isNumberFormat: true,
    id: "totalCost",
    textAlign: "text-end",
  },
  {
    localeId: "TABLE.COLUMN.COLLECTIONCENTRE",
    label: "Collection Center",
    id: "collectionCentreName",
    textAlign: "text-start",
  },
];

export const InputPurchases = () => {
  document.title = "Inputs > Purchases";

  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const [selectedRow, setSelectedRow] = useState<IInputDistribution | null>(
      null,
  );
  const [selectedInputRowId, setSelectedInputRowId] = useState<string | null>(
      null,
  );
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 15,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: 0,
    totalPages: 0,
  });

  const { data, loading }: IInputsDistributionState = useSelector(
      (state: IState) => state.inputs,
  );

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const actionButtons: IButtonProps[] = [
    {
      label: "Add Stock",
      cssClass: "btn btn-primary btn-sm ms-2",
      dataTarget: "#add-stock",
      dataToggle: "modal",
    },
  ];

  const viewDetails = (row: any) => {
    setSelectedRow(row);
    setDrawerVisible(true);
  };

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
    setPage(1);
  };

  const debouncedSearch = useRef(
      debounce((value: string) => {
        setSearchTerm(value);
        setPage(1);
        handleFetchData(page, pagination.pageSize, value);
      }, 800),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleApplyFilters = (FilterValues: any) => {
    setFilters(FilterValues);
    setPage(1);
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [page, searchTerm, filters]);

  const handleFetchData = (
      page: number,
      pageSize: number,
      searchTerm?: string,
  ) => {
    const hub: IHub | undefined = authService.getHub();

    if (remoteRoutes.profiles) {
      const hubId = currentUser?.hubId;

      const parentHubId = hub?.parentHubId;

      const CollectionCentreIds = hub?.collectionCentres?.map(
          (centre) => centre.id,
      );

      let params: any;

      if (parentHubId === null) {
        params = { hubId, page, pageSize, ...filters };
      } else {
        params = {
          hubId,
          parentHubId,
          CollectionCentreIds,
          page,
          pageSize,
          ...filters,
        };
      }

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(
          remoteRoutes.inputsService,
          "/input/purchases",
          params,
      );
      get(
          url,
          (response: IApiResponse) => {
            const {data, paginationMetadata} = response;
            setPagination(paginationMetadata);

            dispatch({
              type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
              payload: data,
            });

            toast.success(toastMessages.default.success, overrideToastDefaults);
          },
          async (error) => {
            toast.error(toastMessages.default.fail, overrideToastDefaults);
          },
          () => {
            dispatch({type: FARMER_PROFILES_CONSTANTS.STOP_FETCH});
            setLoading(false);
          },
      );
    }
  };

  const handleRowClick = (item: any) => {
    setDrawerVisible(true);
    setSelectedRow(item);
    setSelectedInputRowId(item.id);
  };

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
  };

  const formattedData = data.map((row: any) => ({
    ...row,
    formattedDate: printNaturalDateShort(row.date),
  }));

  useEffect(() => {
    setBreadcrumbs([
      { label: "Inputs", url: undefined },
      { label: "Purchases", url: undefined },
    ]);
  }, []);

  return (
      <>
        {loading && <PageHeaderLoader/>}
        {!loading && (
            <>
              <PageHeader breadcrumbs={breadcrumbs} actionButtons={actionButtons}>
                <InnerMenu module={Modules.Inputs}/>
              </PageHeader>
            </>
        )}

        <div className="row">
          <div className={filterVisible ? "col-9" : "col-12"}>
            <TableWrapper>
              <div className="d-flex w-100 align-items-center justify-content-between">
                <div className="input-group w-25">
                  <input
                      type="text"
                      ref={searchInputRef}
                      // onKeyUp={(event) => handleSearch(event)}
                      onChange={handleSearchChange}
                      className="form-control"
                      placeholder="Type to search..."
                  />
                  <span className="input-group-text" id="addon-wrapping">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
                </div>

                <div className={"d-flex"}>
                  <div className="action-buttons">
                    <button
                        onClick={handleFilterClick}
                        type="button"
                        className="btn btn-sm btn-outline-secondary ms-2"
                    >
                      <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                      <span className={"ms-2"}>Filters</span>
                    </button>
                    {showActionButtons &&
                        actionButtons.map((button, index) => {
                          return (
                              <button
                                  data-bs-toggle={button.dataToggle}
                                  data-bs-target={button.dataTarget}
                                  className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                                  key={index}
                                  onClick={button.onClick}
                              >
                                {button.processing ? "Please wait..." : button.label}
                              </button>
                          );
                        })}
                  </div>
                </div>
              </div>
              <XTable
                  selected={[]}
                  checked={false}
                  onRowClick={(item) => handleRowClick(item)}
                  data={formattedData}
                  columns={columns}
                  loading={loading}
              />
              <XPagination
                  currentPage={page}
                  pagination={pagination}
                  dataLength={data.length}
                  setPage={setPage}
              />
            </TableWrapper>
          </div>

          {filterVisible && (
              <div className="col-3">
                <TableWrapper>
                  <div
                      className="border-bottom mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                    <div style={{fontSize: 15, fontWeight: 400}}>Filter</div>
                    <div style={{cursor: "pointer"}}>
                      <FontAwesomeIcon
                          icon={faXmark}
                          onClick={handleFilterClick}
                          size="lg"
                      />
                    </div>
                  </div>
                  <InputPurchasesFilterForm
                      onApplyFilters={handleApplyFilters}
                      setFilters={setFilters}
                  />
                </TableWrapper>
              </div>
          )}
        </div>

        <AddStockForm/>
        <InputPurchaseDetails
            close={() => setDrawerVisible(false)}
            show={drawerVisible}
            selectedRow={selectedRow}
            selectedInputRowId={selectedInputRowId}
        />
      </>
  );
};
