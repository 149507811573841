import {useDispatch, useSelector} from "react-redux";
import {Field, Formik} from "formik";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {get, put} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {districts} from "../../../../../data/districts";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {IOption, XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";
import {useAuth} from "../../../auth";
import {HUBS_CONSTANTS, IHubState} from "../../../../../data/redux/settings/hubsReducer";
import {HubSchema, IHubDto} from "../interfaces/IHubDto";
import {IHub} from "../../../settings/interfaces/IHub";
import {Autocomplete, InputAdornment, TextField} from "@mui/material";
import {IState} from "../../../../../data/types";
import {authService} from "../../../../../data/oidc/AuthService";
import {removeTrailingS} from "../../../../hooks/mauNormalizer";
import {IApiResponse} from "../../../../interfaces/IApiResponse";
import {useIntl} from "react-intl";

type Props = {
    initialValues: IHub,
    parent?: IOption,
    mau?: string
}

const EditHubForm = ({initialValues, parent, mau}: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {currentUser} = useAuth();

    const [hubs, setHubs] = useState<IHub[]>([]);

    const [modalKey, setModalKey] = useState(Date.now());

    const hubOptions = hubs.map((hub: IHub) => ({
        label: hub.name,
        id: hub.id
    }));

    const minimalAggregationUnit = removeTrailingS(mau !== "" ? mau : initialValues.minimumAggregationUnit);

    const handleFetchHubs = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            const hubId = currentUser?.hubId;
            const params = {hubId, page, pageSize};
            const url = buildUrl(remoteRoutes.onboardingService, '/hubs', params);
            get(url,
                (response: IApiResponse) => {
                    const {data} = response;
                    setHubs(data);
                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                () => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: HUBS_CONSTANTS.STOP_FETCH});
                });
        }
    };

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.onboardingService, `/hubs/${values.id}`);
        const data: IHubDto = {
            id: values.id,
            name: values.name,
            email: values.email,
            phoneNumber: `+256${values.phoneNumber}`,
            district: values.district.label,
            parentHubId: values.parentHubId.id ? values.parentHubId.id : initialValues.parentHubId,
            subCounty: values.subCounty,
            village: values.village,
            postalAddress: values.postalAddress,
            minimumAggregationUnit: values.minimumAggregationUnit,
        }

        put(url, data, () => {

            CloseModal("edit-hub", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: HUBS_CONSTANTS.UPDATE_DATA,
                payload: data
            });

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const value = event.target.value;
        if (value.length <= 9) {
            setFieldValue("phoneNumber", value);
        }
    };

    useEffect(() => {
        handleFetchHubs(1, 1000);
    }, []);

    const parentHub = hubOptions?.find(h =>
        h.id == initialValues.parentHubId) ?? {id: '', label: ''}

    const validationSchema = HubSchema(intl);

    return (
        <Formik
            initialValues={{
                ...initialValues,
                parentHubId: parentHub,
                district: initialValues.district ? {
                    label: initialValues.district,
                    value: initialValues.district
                } : null,
                phoneNumber: initialValues.phoneNumber && initialValues.phoneNumber?.length > 9 ? initialValues.phoneNumber?.substring(4) : initialValues.phoneNumber
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting, values, setFieldValue}) => (
                <ModalWrapper
                    id="edit-hub"
                    title={`Edit ${minimalAggregationUnit}`}
                    size="lg"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Postal Address"
                                name="postalAddress"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Minimum Aggregation Unit"
                                name="minimumAggregationUnit"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={true}
                                component={XAutoComplete}
                                options={hubOptions?.filter(hub => hub.id !== initialValues.id)}
                                label="Parent Hub"
                                name="parentHubId"
                                value={values.parentHubId}
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Phone Number"
                                name="phoneNumber"
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">+256</InputAdornment>,
                                    maxLength: 9,
                                    onKeyPress: handleKeyPress,
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, setFieldValue)
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type="email"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Email"
                                name="email"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={districts}
                                label="District"
                                name="district"
                                getOptionLabel={(option: any) => option.label || ""}
                                onChange={(event: any, newValue: any) => {
                                    setFieldValue("district", newValue ? newValue.id : "");
                                }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Sub-county"
                                name="subCounty"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Parish"
                                name="parish"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                type="text"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Village"
                                name="village"
                            />
                        </div>
                    </div>
                </ModalWrapper>
            )}
        </Formik>
    );
};

export default EditHubForm;
