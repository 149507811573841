import { faList, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { IMenuItem } from "../../../utils/MainMenu";
import { useIntl } from "react-intl";

interface MainMenuProps {
  activePage: string;
}

export const ProfilesInnerMenu = ({
  activePage,
}: MainMenuProps): IMenuItem[] => [
  {
    id: "PROFILES.INNERMENU.LISTVIEW",
    label: "List View",
    path: "/farmers",
    isActive: activePage.toLowerCase() === "/farmers",
    icon: faList,
  },
  {
    id: "PROFILES.INNERMENU.MAPVIEW",
    label: "Map View",
    path: "/farmers/map",
    isActive: activePage.toLowerCase() === "/farmers/map",
    icon: faMapPin,
  },
];
