import { Field, Formik, useField } from "formik";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { del, post } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../../data/toastDefaults";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { IHub } from "../../../settings/interfaces/IHub";
import { useIntl } from "react-intl";

interface Props {
  item: IHub;
}

const DeleteCollectionCentreForm = ({ item }: Props) => {
  const intl = useIntl();

  const handleSubmit = (values: any, resetForm: any) => {
    const url = buildUrl(remoteRoutes.onboardingService, `/hubs/${item.id}`);

    del(
      url,
      (response) => {
        CloseModal("delete-client");

        toast.success(
          toastMessages.deleteSuccessful.success,
          overrideToastDefaults,
        );
      },
      (error) => {
        toast.error(toastMessages.deleteSuccessful.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  useEffect(() => {}, []);

  return (
    <Formik
      initialValues={item}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id="delete-client"
            title={`${intl.formatMessage({ id: "FORM.TITLE.DELETECLIENT", defaultMessage: "Delete Client" })}`}
            size="md"
            isDeleteAction={true}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>
              Are you sure you want to delete this client? This action cannot be
              undone.
            </p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteCollectionCentreForm;
