import {XSidebarWrapper} from "../../../components/shared/XSidebarWrapper";
import React, {useEffect, useState} from "react";
import {remoteRoutes} from "../../../../data/constants";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {IFarmerPlot} from "../interfaces/IFarmerPlot";
import {useAuth} from "../../auth";

type Props = {
    close: () => void,
    show: boolean,
    plot: IFarmerPlot | null
}

export function PlotDetails({close, show, plot}: Props) {

    return (
        <>
            <XSidebarWrapper title={"Plot Details"} width={25} close={close} show={show}>
                <div className="d-flex justify-content-between w-100 align-items-baseline border-bottom mb-3">
                    <div className="py-3">
                        <h5>Plot Number: {plot?.plotNumber}</h5>
                    </div>
                </div>
                <div className="p-3 bg-gray rounded-3 mb-2">
                    <div className="row">
                        <div className="col-lg-12 col-12 mb-2">
                            <strong className="small fw-semibold">Agent Name</strong><br/>
                            <span>{plot?.agent || "-"}</span>
                        </div>
                    </div>
                </div>
                {plot?.description && <div className="p-3 bg-gray rounded-3 mb-2">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Description</strong><br/>
                            <span>{plot?.description || "-"}</span>
                        </div>
                    </div>
                </div>}
            </XSidebarWrapper>
        </>
    );
}