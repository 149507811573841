import React, { createContext, FC, useContext } from "react";
import {useSelector} from "react-redux";
import {IState} from "../../data/types";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

type Props = {
  selectedLang: string;
};
const initialState: Props = {
  selectedLang: "english",
};

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls) as Props;
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

const I18nContext = createContext<Props>(initialState);

type Locale =
  | "luganda"
  | "english"
  | "swahili"
  | "lhukonzo"
  | "runyoro"
  | "runya"
  | "ateso"
  | "acholi"
  | "langi"
  | "lusoga";

const useLang = (): Locale => {
  return useContext(I18nContext).selectedLang as Locale;
};

const SymosI18nProvider: FC<any> = ({ children }) => {

  const user: any = useSelector((state: IState) => state.core.user)
  const lang: string = user?.preferred_language ?? 'english'

  return <I18nContext.Provider value={{selectedLang: lang}}>{children}</I18nContext.Provider>;
};

export { SymosI18nProvider, useLang };
