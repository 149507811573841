import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import {faEllipsisH, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Badge from '@mui/material/Badge';
import HubDetails from "./partials/_details";
import HubInputs from "./partials/_inputs";
import HubCollectionCentres from "./partials/_collectionCentres";
import HubCostCentres from "./partials/_costCentres";
import HubProcesses from "./partials/_processes";
import {HUBS_CONSTANTS} from "../../../../data/redux/settings/hubsReducer";
import {IHub, IProduct} from "../../settings/interfaces/IHub";
import HubDetailsLoader from "../../../components/shared/loaders/HubDetailsLoader";
import {remoteRoutes} from "../../../../data/constants";
import {buildUrl} from "../../../../utils/queryBuilder";
import {get} from "../../../../utils/ajax";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {PageHeader} from "../../../components/shared/PageHeader";
import {IHeaderButtonProps} from "../../../components/shared/SettingsPageHeader";
import DeleteHubForm from "./modals/DeleteHubForm";
import EditClientForm from "./modals/EditClientForm";
import HubUsers from "./partials/_users";
import {useAuth} from "../../auth";
import {Mau} from "./partials/_hubs";
import AddProductForm from "../../settings/products/modals/AddProductForm";
import {IState} from "../../../../data/types";
import {IBreadcrumb} from "../../../components/shared/Breadcrumbs";
import {FormattedMessage, useIntl} from "react-intl";

const ClientDetailsPage = () => {
    const intl = useIntl();

    const auth = useAuth();
    const currentUser = auth.currentUser;

    const urlParams = useParams()
    const hubId = urlParams.hubId;
    const [importing, setImporting] = useState<boolean>(false);
    const hub = useSelector((state: IState) =>
        state.hubs?.data?.find((item) => item?.id === hubId)
    );
    const [hubDetails, setHubDetails] = useState(hub);

    const products: IProduct[] = hubDetails?.products || [];
    const [active, setActive] = useState('details');

    const mau = hubDetails?.minimumAggregationUnit === '' ? 'Hubs' : hubDetails?.minimumAggregationUnit;

    const dispatch = useDispatch();

    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    const setLoading = (status: boolean) => {
        dispatch({
            type: HUBS_CONSTANTS.LOADING_DATA,
            payload: status
        })
    }

    document.title = `${hubDetails?.name}`

    useEffect(() => {
        handleFetchData()
    }, [hubId]);

    useEffect(() => {
        if (hub) {
            setHubDetails(hub);
        }
    }, [hub]);

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {

            const url = buildUrl(remoteRoutes.onboardingService, `/hubs/${hubId}`)

            get(url,
                (response: IHub) => {
                    response.products?.map(product => ({
                        ...product,
                        unitString: product.units?.map(u => u.name).join(',')
                    }))
                    setHubDetails(response)

                    dispatch({
                        type: HUBS_CONSTANTS.GET_DETAILS,
                        payload: response
                    })
                    setLoading(false)
                },
                () => toast.error(toastMessages.default.fail, overrideToastDefaults),
                () => dispatch({type: HUBS_CONSTANTS.STOP_FETCH}))
        }
    }

    const actionButtons: IHeaderButtonProps[] = [
        {
            label: `${intl.formatMessage({id: "BUTTONS.EDIT", defaultMessage: "Edit"})}`,
            cssClass: "btn btn-primary btn-sm me-2",
            dataTarget: "#edit-client",
            dataToggle: "modal",
        },
        {
            label: `${intl.formatMessage({id: "BUTTONS.DELETE", defaultMessage: "Delete"})}`,
            cssClass: "btn btn-danger btn-sm",
            dataTarget: "#delete-client",
            dataToggle: "modal",
        },
    ];

    const addProductButton: IHeaderButtonProps[] = [
        {
            label: `${intl.formatMessage({id: "BUTTONS.ADDPRODUCT", defaultMessage: "Add a product"})}`,
            cssClass: "btn btn-outline-primary btn-sm",
            dataTarget: "#add-product",
            dataToggle: "modal",
            onSubmit: (values: any) => {
            },
            processing: importing,
        },
    ];

    useEffect(() => {
        setBreadcrumbs([
            {label: "Clients", url: '/clients'},
            {label: hubDetails?.name ?? "", url: undefined},
        ])
    }, [dispatch])

    return (
        <>
            {!hubDetails && <HubDetailsLoader/>}
            {hubDetails &&
                <>
                    <PageHeader breadcrumbs={breadcrumbs}/>
                    <div className="card shadow-sm border-0 mb-3">
                        <div className="pt-3 px-3">
                            <div className="d-flex justify-content-between w-100 align-items-center mb-3">
                                <div className="d-flex justify-content-start w-50 align-items-center">
                                    <div className="py-3 px-3 rounded-1 bg-primary">
                                        <h2>{hubDetails.name?.split(' ').map(name => name[0]).join('')}</h2>
                                    </div>
                                    <div className="px-3">
                                        <h5>{hubDetails?.name}</h5>
                                        <div className="d-flex gap-1">
                                            {
                                                hubDetails?.products?.map((product, index) => {
                                                    return (
                                                        <span key={index}
                                                              className={`badge text-bg-purple`}>
                                                            {product.name}
                                                        </span>
                                                    );
                                                })
                                            }
                                        </div>

                                        <div className="d-flex w-50 align-items-center gap-3 pt-2">

                                            <Badge className="badge"
                                                   color="primary">345 Farmers</Badge>
                                        </div>

                                    </div>
                                </div>

                                <div className="d-flex gap-2 align-items-center">
                                    <div className="action-buttons">
                                        {addProductButton.map((button, index) => {
                                            return (
                                                <button
                                                    data-bs-toggle={button.dataToggle}
                                                    data-bs-target={button.dataTarget}
                                                    className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                                                    key={index}
                                                    onClick={button.onClick}
                                                >
                                                    {button.deleteIcon && (
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    )}
                                                    {button.processing
                                                        ? `${intl.formatMessage({
                                                            id: "LOADERS.PLEASEWAIT",
                                                            defaultMessage: "Please wait...",
                                                        })}`
                                                        : button.label}
                                                </button>
                                            );
                                        })}
                                    </div>

                                    <div>
                                        <button
                                            className={`btn btn-secondary btn-sm `}
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon icon={faEllipsisH}/>
                                        </button>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            {actionButtons.map((button, index) => {
                                                return (
                                                    <li>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            key={index}
                                                            data-bs-toggle={button.dataToggle}
                                                            data-bs-target={button.dataTarget}
                                                            onClick={() => button.onClick}
                                                        >
                                                            {button.processing
                                                                ? `${intl.formatMessage({
                                                                    id: "LOADERS.PLEASEWAIT",
                                                                    defaultMessage: "Please wait...",
                                                                })}`
                                                                : button.label}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <ul className="nav nav-underline">
                                <li onClick={() => setActive('details')} className="nav-item">
                                    <a className={`nav-link ${active === 'details' ? 'active' : ''}`}
                                       href="#">
                                        <FormattedMessage
                                            id="TABS.DETAILS"
                                            defaultMessage="Details"
                                        />
                                    </a>
                                </li>
                                <li onClick={() => setActive('mau')} className="nav-item">
                                    <a className={`nav-link ${active === 'mau' ? 'active' : ''}`}
                                       href="#">{mau}</a>
                                </li>
                                <li onClick={() => setActive('inputDistribution')} className="nav-item">
                                    <a className={`nav-link ${active === 'inputDistribution' ? 'active' : ''}`}
                                       href="#">
                                        <FormattedMessage
                                            id="TABS.INPUTS"
                                            defaultMessage="Inputs"
                                        />
                                    </a>
                                </li>
                                <li onClick={() => setActive('users')} className="nav-item">
                                    <a className={`nav-link ${active === 'users' ? 'active' : ''}`}
                                       href="#">
                                        <FormattedMessage
                                            id="TABS.USERS"
                                            defaultMessage="Users"
                                        />
                                    </a>
                                </li>
                                <li onClick={() => setActive('collectionCentres')} className="nav-item">
                                    <a className={`nav-link ${active === 'collectionCentres' ? 'active' : ''}`}
                                       href="#">
                                        <FormattedMessage
                                            id="TABS.COLLECTIONCENTERS"
                                            defaultMessage="Collection Centres"
                                        />
                                    </a>
                                </li>
                                <li onClick={() => setActive('costCentres')} className="nav-item">
                                    <a className={`nav-link ${active === 'costCentres' ? 'active' : ''}`}
                                       href="#">
                                        <FormattedMessage
                                            id="TABS.COSTCENTERS"
                                            defaultMessage="Cost Centres"
                                        />
                                    </a>
                                </li>
                                <li onClick={() => setActive('processes')} className="nav-item">
                                    <a className={`nav-link ${active === 'processes' ? 'active' : ''}`}
                                       href="#">
                                        <FormattedMessage
                                            id="TABS.PROCESSES"
                                            defaultMessage="Processes"
                                        />
                                    </a>
                                </li>
                            </ul>

                            <div></div>
                        </div>
                    </div>

                    <div className="card shadow-sm border-0 mb-3">
                        {active === 'details' && <HubDetails hub={hubDetails}/>}
                        {active === 'mau' && <Mau hubId={hubId ?? currentUser?.hubId}/>}
                        {active === 'users' && <HubUsers hubId={hubId ?? currentUser?.hubId}/>}
                        {active === 'inputDistribution' &&
                            <HubInputs hubId={hubId}/>}
                        {active === 'collectionCentres' && <HubCollectionCentres hubId={hubId ?? currentUser?.hubId}/>}
                        {active === 'costCentres' && <HubCostCentres hubId={hubId ?? currentUser?.hubId}/>}
                        {active === 'processes' && <HubProcesses hubId={hubId ?? currentUser?.hubId}/>}
                    </div>
                </>
            }

            {hubDetails && (
                <>
                    <AddProductForm hubId={hubId} items={hubDetails.products ?? []}/>
                    <EditClientForm initialValues={hubDetails} hubId={urlParams.hubId ?? currentUser?.hubId}/>
                    <DeleteHubForm item={hubDetails}/>
                </>
            )}

        </>
    );
}

export default ClientDetailsPage;