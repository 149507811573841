import {
  IHeaderButtonProps,
  SettingsPageHeader,
} from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import { ICollectionCentre, ICostCentre, IProcess } from "../interfaces/IHub";
import { ITableColumn, XTable } from "../../../components/shared/XTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import React, { useCallback, useEffect, useState } from "react";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import {
  ISettingsState,
  SETTINGS_CONSTANTS,
} from "../../../../data/redux/settings/settingsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faFilter,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import CollectionCentreDetails from "./CollectionCentreDetails";
import CreateCollectionCentre from "./modals/CreateCollectionCentre";
import AddCollectionCenters from "./modals/AddCollectionCenters";
import { useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import { FormattedMessage, useIntl } from "react-intl";

interface IProps {
  hubId?: string;
  title?: string;
}

const columns: ITableColumn[] = [
  {
    localeId: "TABLE.COLUMN.COLLECTIONCENTRENAME",
    label: "Collection Center Name",
    id: "name",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.DISTRICT",
    label: "District",
    id: "district",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.VILLAGE",
    label: "Village",
    id: "village",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.LATITUDE",
    label: "Latitude",
    id: "latitude",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    localeId: "TABLE.COLUMN.LONGITUDE",
    label: "Longitude",
    id: "longitude",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];

export const HubCollectionCentersPage = ({ title, hubId }: IProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredCollectionCentres, setFilteredCollectionCentres] = useState<
    ICollectionCentre[]
  >([]);

  const { collectionCentres, loading }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );
  const { pagination, data } = collectionCentres;

  const initialValues = {
    name: "",
    district: "",
    hubId: currentUser?.hubId,
    subCounty: "",
    parish: "",
    village: "",
    latitude: 0,
    longitude: 0,
  };

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [showAddCollectionCentre, setShowAddCollectionCentre] =
    useState<boolean>(true);
  const [selectedCollectionCentre, setSelectedCollectionCentre] =
    useState<ICollectionCentre>(initialValues);

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: SETTINGS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const collectionCenterButtons = () => {
    if (location.pathname.includes("settings")) {
      setShowAddCollectionCentre(false);
    } else {
      setShowAddCollectionCentre(true);
    }
  };

  const filterCollectionCentres = () => {
    if (searchTerm) {
      setFilteredCollectionCentres(
        data.filter((collectionCentre) =>
          collectionCentre.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredCollectionCentres(data);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  useEffect(() => {
    filterCollectionCentres();
    collectionCenterButtons();
  }, [searchTerm, collectionCentres]);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination?.pageSize, searchTerm);
  }, [page]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.onboardingService) {
      let params: any = { hubId, page, pageSize };

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(
        remoteRoutes.onboardingService,
        "/collection/centres",
        params,
      );
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          dispatch({
            type: SETTINGS_CONSTANTS.FETCH_COLLECTION_CENTRES,
            payload: { data, pagination: paginationMetadata },
          });

          toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: ICollectionCentre) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: ICollectionCentre) => {
    setDrawerVisible(!drawerVisible);
    setSelectedCollectionCentre(item);
  };

  const actionButtons: IHeaderButtonProps[] = [
    {
      label: `${intl.formatMessage({ id: "BUTTONS.ADDCOLLECTIONCENTRE", defaultMessage: "New Collection Centre" })}`,
      cssClass: "btn btn-primary btn-sm",
      dataTarget: "#new-collection-centre",
      dataToggle: "modal",
    },
  ];

  return (
    <>
      {title && <SettingsPageHeader title={title} />}
      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-auto">
            <input
              type="text"
              // onKeyUp={(event) => handleSearch(event)}
              onChange={handleSearchChange}
              className="form-control"
              placeholder={intl.formatMessage({
                  id: "TABLE.SEARCH",
                  defaultMessage: "Type to search...",
              })}
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            {/* <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange" />
                            <span className="ms-2">Filters</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">
                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange" />
                        </button> */}

            {showAddCollectionCentre && (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                data-bs-target="#add-collection-centre"
                data-bs-toggle="modal"
              >
                  <FormattedMessage
                      id={"BUTTONS.ADDCOLLECTIONCENTRE"}
                      defaultMessage={"Add Collection Centre"}
                  />

              </button>
            )}

            {!showAddCollectionCentre &&
              showActionButtons &&
              actionButtons.map((button, index) => {
                return (
                  <button
                    data-bs-toggle={button.dataToggle}
                    data-bs-target={button.dataTarget}
                    className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                    key={index}
                    onClick={button.onClick}
                  >
                    {button.processing ? "Please wait..." : button.label}
                  </button>
                );
              })}
          </div>
        </div>

        <XTable
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={filteredCollectionCentres}
          columns={columns}
          loading={loading}
        />

        <XPagination
          dataLength={collectionCentres.data.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <CollectionCentreDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        collectionCentreId={selectedCollectionCentre.id}
      />

      <AddCollectionCenters
        items={filteredCollectionCentres}
        pagination={pagination}
      />
      <CreateCollectionCentre pagination={pagination} hubId={hubId} />
    </>
  );
};
