import {useDispatch} from "react-redux";
import {Field, Formik, useField} from "formik";
import {useEffect, useState} from "react";
import {CollectionCentreSchema} from "../interfaces/CollectionCentreSchema";
import {ICollectionCentre} from "../../interfaces/IHub";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {districts} from "../../../../../data/districts";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";
import {useAuth} from "../../../auth";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {post} from "../../../../../utils/ajax";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {useParams} from "react-router-dom";
import {IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {useIntl} from "react-intl";

type Props = {
    hubId?: string
    pagination: IPaginationMetadata
}

const CreateCollectionCentre = ({hubId, pagination}: Props) => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const {currentUser} = useAuth();
    const urlParams = useParams()
    hubId = hubId ?? urlParams.hubId;

    const [modalKey, setModalKey] = useState(Date.now()); // key for resetting modal

    const initialValues = {
        name: '',
        district: null,
        hubId: null,
        subCounty: null,
        parish: null,
        village: null,
        latitude: null,
        longitude: null
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, '/collection/centres')
        const data: ICollectionCentre = {
            ...values,
            district: values.district.label,
            hubId: hubId ?? currentUser?.hubId,
        }

        post(url, data, (response) => {

            CloseModal("new-collection-centre", () => {
                setSubmitting(false);
                resetForm();
            })


            dispatch({
                type: SETTINGS_CONSTANTS.POST_COLLECTION_CENTRES,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now()); // Reset modal key to force re-render
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const validationSchema = CollectionCentreSchema(intl)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="new-collection-centre"
                    title={`${intl.formatMessage({
                        id: "FORM.TITLE.NEWCOLLECTIONCENTRE",
                        defaultMessage: "New Collection Centre"
                    })}`}
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({id: "FORM.CLIENTNAME", defaultMessage: "Client name"})}`}
                                name="name"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={districts}
                                label={`${intl.formatMessage({id: "FORM.DISTRICT", defaultMessage: "District"})}`}
                                name="district"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({id: "FORM.SUBCOUNTY", defaultMessage: "Sub county "})}`}
                                name="subCounty"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({id: "FORM.PARISH", defaultMessage: "Parish"})}`}
                                name="parish"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({id: "FORM.VILLAGE", defaultMessage: "Village"})}`}
                                name="village"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({id: "FORM.LATITUDE", defaultMessage: "Latitude"})}`}
                                name="latitude"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label={`${intl.formatMessage({id: "FORM.LONGITUDE", defaultMessage: "Longitude"})}`}
                                name="longitude"
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    );
};

export default CreateCollectionCentre;
