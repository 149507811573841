import {useDispatch, useSelector} from "react-redux";
import {Field, Formik} from "formik";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {IHub} from "../../settings/interfaces/IHub";
import {authService} from "../../../../data/oidc/AuthService";
import {remoteRoutes} from "../../../../data/constants";
import {useAuth} from "../../auth";
import {buildUrl} from "../../../../utils/queryBuilder";
import {post} from "../../../../utils/ajax";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {BUYERS_CONSTANTS} from "../../../../data/redux/inventory/buyersReducer";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {SellPurchasesSchema} from "../schemas/SellBatchSchema";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {XAutoComplete} from "../../../components/shared/forms/XAutoComplete";
import moment from "moment/moment";
import {IState} from "../../../../data/types";
import useFetchHubs from "../../../hooks/useFetchHubs";
import {useIntl} from "react-intl";

type Props = {
    purchases: any[]
}

export const SellPurchasesForm = ({purchases}: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {currentUser} = useAuth();
    const hub: IHub | any = authService.getHub()
    const [selected, setSelected] = useState<any[]>([])

    const {fetchHubs, pagination} = useFetchHubs()
    const {data, loading}: any = useSelector((state: IState) => state.hubs)

    useEffect(() => {
        fetchHubs(pagination.currentPage, pagination.pageSize, {})
    }, [pagination.currentPage])

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        buyer: ''
    }

    const handleRowClick = (row: any) => {
        setSelected(row)
    };

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.inventoryService, `/purchases/sale`);
        const data = {
            date: moment().toISOString(),
            hubId: currentUser?.hubId,
            purchaseIds: purchases,
            buyerId: values.buyer.id,
            buyerName: values.buyer.label
        }

        post(url, data, () => {

            CloseModal("sell-purchases", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({type: BUYERS_CONSTANTS.POST_BUYER, payload: data});
            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };

    const validationSchema = SellPurchasesSchema(intl)

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting, values, setFieldValue}) => (
                <ModalWrapper
                    id="sell-purchases"
                    title={`${intl.formatMessage({ id: "FORM.TITLE.SELECTBUYER", defaultMessage: "Select Buyer" })}`}
                    size="md"
                    key={modalKey}
                    position="top"
                    submitBtnLabel={`${intl.formatMessage({
                        id: "FORM.BUTTON.CONFIRMSALE",
                        defaultMessage: "Confirm Sale"
                    })}`}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="scrollable-content">
                        <div className="row">

                            <div className="col-lg-12">
                                <Field
                                    type="string"
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    label={`${intl.formatMessage({ id: "FORM.BUYER", defaultMessage: "Buyer" })}`}
                                    variant={"standard"}
                                    options={data?.map((buyer: any, index: number) => ({
                                        label: buyer?.name,
                                        id: buyer?.id
                                    }))}
                                    name="buyer"
                                />

                                {/*<XTable*/}
                                {/*    selected={selected}*/}
                                {/*    onRowClick={(row) => handleRowClick(row)}*/}
                                {/*    data={data} columns={columns}*/}
                                {/*    loading={loading}/>*/}
                            </div>

                        </div>
                    </div>
                </ModalWrapper>
            )}
        </Formik>
    );
};