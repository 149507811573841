import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {Field, Formik} from "formik";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {get, post} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {useAuth} from "../../../auth";
import {useDispatch, useSelector} from "react-redux";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {ICostCentre, IUnit} from "../../interfaces/IHub";
import {IState} from "../../../../../data/types";
import {IApiResponse, IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {Autocomplete, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {CostCentreSchema} from "../interfaces/ICostCentreSchema";
import {useIntl} from "react-intl";

type Props = {
    hubId?: string
    pagination: IPaginationMetadata
}

const CreateCostCentre = ({hubId, pagination}: Props) => {
    const intl = useIntl();

    const auth = useAuth();
    const currentUser = auth?.currentUser;
    const dispatch = useDispatch();

    const units = useSelector((state: IState) => state.settings.units?.data || []);

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues: ICostCentre = {
        hubId: '',
        name: '',
        description: '',
        units: []
    }

    useEffect(() => {
        handleFetchData();
    }, [dispatch]);

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            const url = buildUrl(remoteRoutes.onboardingService, "/units");

            get(url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;

                    dispatch({
                        type: SETTINGS_CONSTANTS.FETCH_UNITS,
                        payload: data,
                    });

                    // toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                });
        }
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, '/cost/centres')
        const data: ICostCentre = {
            name: values.name,
            hubId: hubId ?? currentUser?.hubId,
            units: values.units,
            description: values.description
        }

        post(url, data, (response) => {

            CloseModal("new-cost-centre", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_COST_CENTRES,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const validationSchema = CostCentreSchema(intl)
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>

                {({handleSubmit, isSubmitting, touched, errors, values, setFieldValue}) => {
                    return <ModalWrapper
                        id="new-cost-centre"
                        title={`${intl.formatMessage({
                            id: "FORM.TITLE.CREATENEWCOSTCENTRE",
                            defaultMessage: "Create a new Cost Centre"
                        })}`}
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.NAME",
                                        defaultMessage: "Name"
                                    })}`}
                                    name="name"
                                />
                            </div>

                            <div className="col-lg-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label={`${intl.formatMessage({
                                        id: "FORM.DESCRIPTION",
                                        defaultMessage: "Description"
                                    })}`}
                                    name="description"
                                />
                            </div>

                            <div className="col-lg-12">
                                <Field name="units">
                                    {({}) => (
                                        <Autocomplete
                                            multiple
                                            getOptionLabel={(option: IUnit) => option.name}
                                            options={units}
                                            onChange={(event, value) => {
                                                setFieldValue('units', value?.map(prod => ({
                                                    id: prod.id,
                                                    name: prod.name
                                                })));
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${intl.formatMessage({
                                                        id: "FORM.SELECTUNITS",
                                                        defaultMessage: "Select units"
                                                    })}`}
                                                    variant="standard"
                                                    error={touched.units && Boolean(errors.units)}
                                                    helperText={touched.units && errors.units ? errors.units : ''}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>


                        </div>

                    </ModalWrapper>
                }}

            </Formik>

        </>
    );
}

export default CreateCostCentre;
